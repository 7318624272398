<template>
  <v-layout mt-12>
    <v-flex>
      <Category :onHandleClick="getTop10" />

      <v-layout class=" justify-center">
        <v-flex>
          <v-layout class="justify-space-between" wrap>
            <!-- Component ==> Export 5 Years -->
            <v-flex lg6 md6 sm12 xs12 pr-6 class="justify-center">
              <!-- Topic Card -->
              <v-layout>
                <v-flex class="mr-2 shrink d-flex align-center top-10">
                  TOP<span style="color: #052E5C">10</span>
                </v-flex>
                <v-flex class="shrink d-flex align-center font-20">
                  Export 5 Years
                </v-flex>
              </v-layout>

              <div class="line"></div>

              <!-- Card Component -->
              <v-card flat class="pa-3 mt-3">
                <Top10 v-if="top10Export5Years" :data="top10Export5Years" />
                <v-layout v-else justify-center>
                  <v-flex class="d-flex align-center justify-center">
                    Data not found!
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>

            <v-divider
              :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
            ></v-divider>

            <!-- Component ==> Research 5 Years -->
            <v-flex lg6 md6 sm12 xs12 pl-6 class="justify-center">
              <!-- Topic Card -->
              <v-layout>
                <v-flex class="mr-2 shrink d-flex align-center top-10">
                  TOP<span style="color: #052E5C">10</span>
                </v-flex>
                <v-flex class="shrink d-flex align-center font-20">
                  Product
                </v-flex>
              </v-layout>
              <div class="line"></div>

              <!-- Card Component -->
              <v-card flat class="pa-3 mt-3">
                <Top10 v-if="top10Product5Years" :data="top10Product5Years" />
                <v-layout v-else justify-center>
                  <v-flex class="d-flex align-center justify-center">
                    Data not found!
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider class="my-6"></v-divider>

      <v-layout class=" justify-center">
        <v-flex>
          <v-layout class="justify-space-between" wrap>
            <!-- Component ==> Social Network 4 Month  -->
            <v-flex lg6 md6 sm12 xs12 pr-6 class="justify-center">
              <!-- Topic Card -->
              <v-layout>
                <v-flex class="mr-2 shrink d-flex align-center top-10">
                  TOP<span style="color: #052E5C">10</span>
                </v-flex>
                <v-flex class="shrink d-flex align-center font-20">
                  Research 5 Years
                </v-flex>
              </v-layout>

              <div class="line"></div>

              <!-- Card Component -->
              <v-card flat class="pa-3 mt-3">
                <Top10 v-if="top10Research5Years" :data="top10Research5Years" />
                <v-layout v-else justify-center>
                  <v-flex class="d-flex align-center justify-center">
                    Data not found!
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>

            <v-divider
              :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
            ></v-divider>

            <!-- Component ==> Product 5 Years  -->
            <v-flex lg6 md6 sm12 xs12 pl-6 class="justify-center">
              <!-- Topic Card -->
              <v-layout>
                <v-flex class="mr-2 shrink d-flex align-center top-10">
                  TOP<span style="color: #052E5C">10</span>
                </v-flex>
                <v-flex class="shrink d-flex align-center font-20">
                  Social Network 4 Month
                </v-flex>
              </v-layout>
              <div class="line"></div>

              <!-- Card Component -->
              <v-card flat class="pa-3 mt-3">
                <Top10
                  v-if="top10SocialNetwork4Month"
                  :data="top10SocialNetwork4Month"
                />
                <v-layout v-else justify-center>
                  <v-flex class="d-flex align-center justify-center">
                    Data not found!
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <pre>
        <!-- {{ top10SocialNetwork4Month.title }} -->
      </pre>
    </v-flex>
  </v-layout>
</template>

<script>
import Top10 from "../../components/top10/Top10.vue";
import Category from "../layout/Category.vue";
// import json from "../../services/api/json/top10.json";
export default {
  components: { Top10, Category },
  data() {
    return {
      top10Export5Years: null,
      top10Product5Years: null,
      top10Research5Years: null,
      top10SocialNetwork4Month: null,
    };
  },
  created() {
    const id = this.$route.params.id ? this.$route.params.id : 1;
    this.getTop10(id);
  },
  methods: {
    getTop10(id) {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.$store
        .dispatch("category/getTop10", id)
        .then((res) => {
          this.top10Export5Years = null;
          this.top10Product5Years = null;
          this.top10Research5Years = null;
          this.top10SocialNetwork4Month = null;
          if (res) {
            const data = JSON.parse(res.category_stat_result_json);
            this.top10Export5Years = data.top10Export5Years;
            this.top10Product5Years = data.top10Product5Years;
            this.top10Research5Years = data.top10Research5Years;
            this.top10SocialNetwork4Month = data.top10SocialNetwork4Month;
            console.log(this.top10Export5Years);
          }
          loader.hide();
        })
        .catch((err) => loader.hide());
    },
  },
};
</script>

<style></style>
