<template>
  <div>
    <v-row>
      <v-col lg="6" cols="12">
        <v-row>
          <v-col>
            <v-layout class="flex-column align-end text-end">
              <v-btn
                rounded
                color="#02B25F"
                dark
                @click="dataChartFilterHandler()"
              >
                <img :src="filterIcon" />
                <span class="pl-2">เลือกแสดง</span>
              </v-btn>
              <v-flex>
                <v-select
                  ref="selectFilterOnlineTrending"
                  class="economic"
                  :style="`opacity: ${filterChartToggle ? '1' : '0'};`"
                  :items="oldProductData.category_stat_result_json.datasets"
                  item-text="label"
                  item-value="label"
                  attach
                  chips
                  dense
                  v-model="filterProductValue"
                  @change="productValueChange"
                  return-object
                  color="#02B25F"
                  outlined
                  :menu-props="{ offsetY: true, value: filterChartToggle }"
                  multiple
                ></v-select>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row class="align-end">
          <v-col md="11" cols="10">
            <p class="font-11">จำนวนโพสต์</p>
            <LineChart
              v-if="productData"
              :data="lineChartDataTransform"
            ></LineChart>
          </v-col>
          <v-col md="1" cols="2">
            <p class="font-11">เดือน</p>
          </v-col>
          <v-col cols="12">
            <v-layout justify-center wrap pl-6>
              <v-flex
                class="mt-3"
                style="width: 20%"
                v-for="(item, i) in oldProductData.category_stat_result_json
                  .datasets"
                :key="i"
              >
                <v-layout>
                  <v-flex class="shrink d-flex align-center mr-1">
                    <div
                      style="height: 10px; width: 10px"
                      :style="{ backgroundColor: item.borderColor }"
                    ></div>
                  </v-flex>

                  <v-flex style="" class="font-11 d-flex flex-start">
                    {{ item.label }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="6" cols="12">
        <v-row>
          <v-col>
            <v-layout class="flex-column align-end text-end">
              <v-btn rounded color="#02B25F" dark @click="dataFilterHandler()">
                <img :src="filterIcon" />
                <span class="pl-2">เลือกแสดง</span>
              </v-btn>
              <v-flex>
                <!-- <v-select
              class="economic"
              v-if="filterToggle"
              :items="oldProductData.category_stat_result_json.datasets"
              item-text="label"
              item-value="label"
              attach
              chips
              dense
              @change="productValueChange"
              return-object
              color="#02B25F"
              outlined
              :menu-props="{ offsetY: true, value: filterToggle }"
              multiple
            ></v-select> -->
                <v-select
                  class="economic"
                  :style="`opacity: ${filterToggle ? '1' : '0'};`"
                  :items="oldEmotionData.category_stat_result_json.datasets"
                  item-text="label"
                  item-value="label"
                  v-model="filterEmotionValue"
                  attach
                  chips
                  dense
                  @change="meaningValueChange"
                  return-object
                  color="#02B25F"
                  outlined
                  :menu-props="{ offsetY: true, value: filterToggle }"
                  multiple
                ></v-select>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row class="align-end">
          <v-col cols="10">
            <p class="font-11">จำนวน (Sentiment)</p>
            <BarChart
              v-if="emotionData"
              :data="emotionData.category_stat_result_json"
              :options="trendingEmotionOption"
            ></BarChart>
          </v-col>
          <v-col cols="2">
            <p class="font-11">ทรัพยากรชีวภาพ</p>
          </v-col>
          <v-col cols="12">
            <v-layout justify-center pl-6>
              <div
                class="px-2"
                v-for="(item, i) in emotionData.category_stat_result_json
                  .datasets"
                :key="i"
              >
                <v-layout>
                  <v-flex class="shrink d-flex align-center mr-1">
                    <div
                      style="height: 10px; width: 10px"
                      :style="{ backgroundColor: item.backgroundColor }"
                    ></div>
                  </v-flex>

                  <v-flex style="" class="font-11 d-flex flex-start">
                    {{ item.label }}
                  </v-flex>
                </v-layout>
              </div>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LineChart from "../Chart/OnlineTrending/LineChart.vue";
import BarChart from "../Chart/OnlineTrending/BarChart.vue";
import Vue from "vue";
export default {
  props: ["trendingEmotion", "trendingProduct"],
  watch: {
    trendingEmotion: function(newVal, oldVal) {
      this.emotionData = newVal;
      this.oldEmotionData = newVal;
    },
    trendingProduct: function(newVal, oldVal) {
      this.productData = newVal;
      this.oldProductData = newVal;
      this.isProductRender = true;
    },
  },
  created() {
    this.emotionData = this.trendingEmotion;
    this.oldEmotionData = this.trendingEmotion;

    this.productData = this.trendingProduct;
    this.oldProductData = this.trendingProduct;
    this.isProductRender = true;
  },
  methods: {
    dataFilterHandler() {
      this.filterToggle = !this.filterToggle;

      this.$nextTick(() => {
        if (this.$refs["selectFilterOnlineTrending"]) {
          this.$refs["selectFilterOnlineTrending"].activateMenu();
        }
      });
    },
    dataChartFilterHandler() {
      this.filterChartToggle = !this.filterChartToggle;

      this.$nextTick(() => {
        if (this.$refs["selectFilterOnlineTrending"]) {
          this.$refs["selectFilterOnlineTrending"].activateMenu();
        }
      });
    },
    meaningValueChange(value) {
      let tempEmotionData = this.oldEmotionData.category_stat_result_json
        .datasets;
      let tempEmotionLabel = this.oldEmotionData.category_stat_result_json
        .labels;
      let tempArr = [];
      this.isProductRender = false;

      if (value.length === 0) {
        tempArr = tempEmotionData;
      } else {
        for (const item of value) {
          tempArr.push(
            tempEmotionData.find(
              (x) => item.label.toLowerCase() === x.label.toLowerCase()
            )
          );
        }
      }

      const emotionModel = {
        category_stat_result_json: {
          datasets: tempArr,
          labels: tempEmotionLabel,
        },
      };
      this.emotionData = emotionModel;
      this.mappingValueBetweenChart();

      this.isProductRender = true;
    },
    mappingValueBetweenChart() {
      const labelSelectedArray =
        this.filterProductValue.length > 0
          ? this.filterProductValue.map((filter) => filter.label)
          : this.oldEmotionData.category_stat_result_json.labels;

      let emotionDataLabels = this.oldEmotionData.category_stat_result_json
        .labels;
      emotionDataLabels = emotionDataLabels.filter((label, index) => {
        const isIncludeInNewFilter = labelSelectedArray.includes(label);
        if (isIncludeInNewFilter) {
          // emotionData.datasets = emotionData.datasets.map((dataset) => ({
          //   ...dataset,
          //   data: dataset.data.splice(index, 1),
          // }));
        }
        return isIncludeInNewFilter;
      });
      const emotionModel = {
        category_stat_result_json: {
          labels: emotionDataLabels,
          datasets: this.emotionData.category_stat_result_json.datasets,
        },
      };
      this.emotionData = emotionModel;
    },
    productValueChange(value) {
      let tempProductData = this.oldProductData.category_stat_result_json
        .datasets;
      let tempProductLabel = this.oldProductData.category_stat_result_json
        .labels;
      let tempArr = [];
      this.isProductRender = false;

      if (value.length === 0) {
        tempArr = tempProductData;
      } else {
        for (const item of value) {
          tempArr.push(tempProductData.find((x) => item.label === x.label));
        }
      }
      const productModel = {
        category_stat_result_json: {
          datasets: tempArr,
          labels: tempProductLabel,
        },
      };
      this.productData = productModel;
      this.mappingValueBetweenChart();
      this.isProductRender = true;
    },
    datasetsPointRadiusAdd(data) {
      data.datasets = data.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 5,
      }));

      return data;
    },
  },
  computed: {
    lineChartDataTransform() {
      let data = this.productData.category_stat_result_json;

      data = this.datasetsPointRadiusAdd(data);
      return data;
    },
  },
  data() {
    return {
      filterIcon: require("../../assets/images/icon/filter.png"),
      filterToggle: false,
      filterChartToggle: false,
      isProductRender: false,
      productData: null,
      oldProductData: null,
      emotionData: null,
      filterEmotionValue: [],
      filterProductValue: [],
      oldEmotionData: null,
      trendingEmotionOption: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.4,
              ticks: {
                fontSize: 14,
                fontFamily: "Prompt",
                fontStyle: "bold",
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  components: { LineChart, BarChart },
};
</script>
<style></style>
