<template>
  <div>
    <div style="background-color:#001034; color: white;">
      <v-row class="pa-2">
        <v-col class="mt-auto pa-0" cols="auto">
          <img
            @click="homeRedirectHandler()"
            width="100"
            src="../../assets/images/logo.png"
            alt=""
          />
        </v-col>

        <!-- <v-col cols="auto" class="my-auto"> {{ productName }} </v-col> -->
        <v-col class="my-auto pa-md-0">
          <v-autocomplete
            class="px-md-2 px-0"
            background-color="white"
            placeholder="ค้นหา ..."
            rounded
            style="width: 100%; max-width: 700px;"
            :items="products"
            :item-text="textSearch"
            item-value="key_id"
            v-model="keyId"
            @change="searchHandler"
            hide-details
          >
            <template slot="append">
              <v-btn
                height="30"
                style="min-width: 0;margin-top: 0;background-color: #15B86B; border-radius: 50px; color:white; "
                class="mb-1 pa-1"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </div>
    <v-container>
      <v-row class="my-2">
        <v-col cols="auto" class="my-auto">
          ผลการค้นหา {{ data.total }} รายการ
        </v-col>
        <v-col cols="auto" class="my-auto">
          จาก {{ data.current_page }} - {{ data.last_page }} หน้า</v-col
        >
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-row
            class="ma-2 py-5"
            v-for="research in data.data"
            :key="research.id"
          >
            <v-col class="ma-0 py-0 font-20" cols="12">
              <v-row>
                <v-col cols="12" lg="5">
                  <a target="_blank" :href="research.thaijo.article_url">{{
                    research.thaijo.title
                  }}</a>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              style="color: #006621;"
              class="ma-0 py-0"
              cols="12"
            >
              <v-row>
                <v-col cols="12" lg="5">
                  <span>
                    {{ research.thaijo.article_url }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ma-0 py-0" cols="12">
              <v-row>
                <v-col cols="12" lg="5">
                  {{ subStringDescription(research.thaijo.abstract_clean) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ma-0 py-0 font-14" cols="12">
              <v-row>
                <v-col cols="12" lg="5">
                  {{ research.thaijo.issue_date_published }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <paginate
            :page-count="pageCount"
            :click-handler="getResults"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            page-class="page-el"
            prev-class="page-prev-el"
            next-class="page-next-el"
            active-class="page-active-el"
            v-model="page"
          >
          </paginate>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      keyId: null,
      productName: null,
      data: {},
      pageCount: 1,
      page: 1,
      products: [],
    };
  },
  async created() {
    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });
    this.keyId = +this.$route.params.keyId;
    this.productName = this.$route.query.productName;
    let research = await this.getInResearchList({
      key: +this.$route.params.keyId,
      page: this.page,
    });
    this.data = research;

    this.pageCount = research.last_page;

    let products = await this.getAllProducts();
    this.products = products;

    loader.hide();
  },
  methods: {
    ...mapActions("product", ["getInResearchList", "getAllProducts"]),
    homeRedirectHandler() {
      this.$router.push("/");
    },
    async getResults(page = 1) {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.page = page;
      let research = await this.getInResearchList({ key: this.keyId, page });
      this.data = research;
      window.scrollTo(0, 0);
      loader.hide();
    },
    subStringDescription(description) {
      if (description.length > 255) {
        return description.substring(0, 255) + "...";
      }
    },
    textSearch(item) {
      return `${item.name_th} (${item.name_en})`;
    },
    async searchHandler() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.page = 1;

      // this.keyId = this.$route.params.keyId;

      this.productName = this.$route.query.productName;
      let research = await this.getInResearchList({
        key: this.keyId,
        page: this.page,
      });
      this.data = research;

      this.pageCount = research.last_page;

      loader.hide();
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  list-style-type: none;
  color: white;
}

.page-el {
  padding: 1rem;
}

.page-el {
  padding: 1rem;
}

.page-el {
  padding: 1rem;
}

.page-el > a {
  color: rgb(143, 143, 143) !important;
}

.page-prev-el {
  padding: 1rem;
}

.page-prev-el > a {
  color: black !important;
}

.page-next-el {
  padding: 1rem;
}

.page-next-el > a {
  color: black !important;
}

.page-active-el > a {
  color: black !important;
}
</style>
