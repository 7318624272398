<template>
  <v-card
    color="gray"
    style="background-color: #FAF8F8"
    flat
    class="px-4 py-4 cursor--pointer"
    :max-height="height"
    :width="width"
    @click="openUrl(url)"
  >
    <v-row>
      <v-col cols="12" md="4" class="d-flex align-center justify-center">
        <v-img
          aspect-ratio="1"
          cover
          :width="'100%'"
          :src="image ? image : require('../assets/images/category/cate1.png')"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="8" shrink class="px-2">
        <div class=" font-weight-bold" style="overflow: hidden; ">
          {{ tilte }}
        </div>
        <div class="font-14" style="overflow: hidden;">
          {{ detail }}
        </div>
        <div class="font-14" style="vertical-align: bottom;">
          <span style="" v-if="price">
            <span v-if="!isNaN(price)">ราคา {{ price }} {{ currency }}</span>
            <span v-else>{{ price }}</span>
          </span>
          <span v-else> สินค้าหมด </span>
        </div>
        <div class="font-14" style="overflow: hidden;">ร้าน : {{ brand }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    tilte: {
      type: String,
      default: "",
    },
    detail: {
      type: String,
      default: "",
    },
    price: {
      type: [String, Number],
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    brand: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "#",
    },
    currency: {
      type: String,
      default: "บาท",
    },
  },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 300;
        case "lg":
          return "100%";
        case "xl":
          return "100%";
      }
      return 300;
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return 530;
        case "xl":
          return 530;
      }
      return 530;
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 500;
        case "sm":
          return 500;
        case "md":
          return 300;
        case "lg":
          return 300;
        case "xl":
          return 300;
      }
      return 300;
    },
  },
  methods: {
    openUrl(url) {
      // console.log(url);
      // window.open(url);
    },
  },
};
</script>

<style></style>
