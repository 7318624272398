<template>
  <div>
    <Navbar />

    <v-container fluid>
      <v-row style="min-height: calc(100vh - 150px)">
        <v-col class="my-auto" align-self="center">
          <v-row justify="center">
            <v-col cols="auto">
              <img width="240" :src="require('../../assets/search-logo.png')" />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="mt-4 " cols="auto">
              <span>
                ชนิด
              </span>
            </v-col>
            <v-col class="my-auto" cols="auto">
              <v-select
                :item-text="typeName"
                item-value="id"
                v-model="typeItem"
                :items="typeItems"
                class="m-auto"
                @change="filterHandler"
                rounded
                solo
              >
              </v-select>
            </v-col>
            <v-col class="mt-4" cols="auto">
              <span>
                ด้านเศรษฐกิจ
              </span>
            </v-col>
            <v-col class="my-auto" cols="auto">
              <v-select
                item-text="name"
                item-value="id"
                :items="ecoItems"
                v-model="ecoItem"
                class="m-auto"
                rounded
                solo
                @change="filterHandler"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              class="my-auto"
              :style="$vuetify.breakpoint.mdAndUp ? `min-width: 900px` : ``"
              cols="auto"
            >
              <v-autocomplete
                :items="filterProduct"
                :item-text="textSearch"
                item-value="id"
                class="m-auto"
                placeholder="ค้นหา ..."
                rounded
                solo
                @change="searchNavHandler"
              >
                <template slot="append">
                  <v-btn
                    height="30"
                    style="min-width: 0;margin-top: 0;background-color: #15B86B; border-radius: 50px; color:white; "
                    class="mb-1 pa-1"
                  >
                    <v-icon>
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- <v-row v-if="advanceSearch" justify="center">
            <v-col class="mt-4 " cols="auto">
              <span>
                ชนิด
              </span>
            </v-col>
            <v-col class="my-auto" cols="auto">
              <v-select :items="typeItems" class="m-auto" rounded solo>
              </v-select>
            </v-col>
            <v-col class="mt-4" cols="auto">
              <span>
                ด้านเศรษฐกิจ
              </span>
            </v-col>
            <v-col class="my-auto" cols="auto">
              <v-select :items="ecoItems" class="m-auto" rounded solo>
              </v-select>
            </v-col>
          </v-row> -->
        </v-col>
      </v-row>
    </v-container>

    <Footer />
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import Navbar from "../layout/Navbar.vue";
import { mapActions } from "vuex";

export default {
  components: { Navbar, Footer },
  data() {
    return {
      advanceSearch: false,
      typeItems: [],
      ecoItems: [],
      products: [],
      filterProduct: [],
      ecoItem: null,
      typeItem: null,
    };
  },
  async created() {
    this.products = await this.getAllProducts();
    this.typeItems = await this.getBioTypes();

    this.filterProduct = this.products;

    let category = await this.getCategory();

    this.ecoItems = category;
  },
  methods: {
    ...mapActions("product", ["getAllProducts"]),
    ...mapActions("category", ["getCategory", "getBioTypes"]),
    typeName(item) {
      return `${item.name_th} (${item.name_en})`;
    },
    textSearch(item) {
      return `${item.name_th} (${item.name_en})`;
    },
    searchNavHandler(value) {
      this.$router.push(`/product/${value}`);
    },
    filterHandler() {
      let filterProducts = this.products;
      if (this.ecoItem) {
        filterProducts = filterProducts.filter((product) => {
          if (this.ecoItem === 1) {
            return true;
          } else if (this.ecoItem === 2) {
            return product.keyword.isSpa;
          } else if (this.ecoItem === 3) {
            return product.keyword.isTravel;
          } else if (this.ecoItem === 4) {
            return product.keyword.isFood;
          } else if (this.ecoItem === 5) {
            return product.keyword.isHealth;
          } else if (this.ecoItem === 6) {
            return product.keyword.isBeauty;
          }
        });
      }

      console.log(this.ecoItem);

      if (this.typeItem) {
        filterProducts = filterProducts.filter(
          (product) => this.typeItem === product.keyword.bioType
        );
      }

      console.log(filterProducts);

      this.filterProduct = filterProducts;
    },
  },
};
</script>
