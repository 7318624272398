<script>
import { Bar, mixins } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["data", "options"],
  mounted() {
    this.renderChart(this.data, this.options);
  },
  watch: {
    data: function(newVal, oldVal) {
      this.renderChart(newVal, this.options);
    },
  },
};
</script>

<style scoped></style>
