<template>
  <div v-if="categoryEcommerceSocialIn && categoryEcommerceSocialOut">
    <span>
      สินค้ายอดนิยมในประเทศ
    </span>
    <div
      class="d-flex justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin: 0 -6rem' : 'margin: 0 -1.6rem'
      "
    >
      <v-slide-group
        class="pa-4 custom--slide"
        center-active
        show-arrows
        ref="slide1"
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
      >
        <v-slide-item
          class="my-0"
          v-for="(item, i) in JSON.parse(
            categoryEcommerceSocialIn.category_stat_result_json
          )"
          :key="i"
          @click.native="onClickItem(item.id)"
        >
          <CardProduct
            class="ma-2"
            :tilte="item.product_name_th"
            :detail="item.detail"
            :price="item.price"
            :image="item.image"
            :url="item.url"
            :brand="item.brand"
          />
        </v-slide-item>
      </v-slide-group>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="mt-5">
      สินค้ายอดนิยมต่างประเทศ
    </div>
    <div
      class="d-flex justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin: 0 -6rem' : 'margin: 0 -1.6rem'
      "
    >
      <v-slide-group
        class="pa-4 custom--slide"
        center-active
        show-arrows
        ref="slide1"
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
      >
        <v-slide-item
          class="my-0"
          v-for="(item, n) in JSON.parse(
            categoryEcommerceSocialOut.category_stat_result_json
          )"
          :key="n"
          @click.native="onClickItem(item.id)"
        >
          <CardProduct
            class="ma-2"
            :tilte="item.product_name_th"
            :detail="item.detail"
            :price="item.price"
            :image="item.image"
            :url="item.url"
            :brand="item.brand"
            currency="dollars"
          />
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import CardProduct from "../CardProduct.vue";
import { mapState } from "vuex";
export default {
  components: { CardProduct },
  data: () => ({
    model: null,
  }),
  computed: {
    ...mapState({
      categoryEcommerceSocialIn: (state) =>
        state.category.categoryEcommerceSocialIn,
      categoryEcommerceSocialOut: (state) =>
        state.category.categoryEcommerceSocialOut,
    }),
  },
  created() {},
  methods: {
    onClickItem(id) {
      // this.$swal.fire({
      //   title: "Comming Soon!",
      //   text: "Todo: Go to Detail page for Pruduct ID : " + id,
      //   confirmButtonColor: "#02B25F",
      // });
    },
  },
};
</script>

<style>
/* Custom Icon Slide */
.custom--slide .v-icon.v-icon {
  font-size: 30px !important;
  border: 1px solid;
  border-radius: 50%;
  background-color: #828282;
  color: #fff;
}
.custom--slide .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(228, 224, 224, 0.76) !important;
  background-color: #8282828f;
}
</style>
