<template>
  <div>
    <v-layout>
      <v-flex>
        <v-card flat class="pa-md-3 mt-3">
          <div class="d-flex " v-if="datasets || datasets.length > 0">
            <v-slide-group
              class="pa-md-4 custom--slide"
              center-active
              show-arrows
              ref="slide1"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
            >
              <v-slide-item
                class="my-0"
                v-for="(item, i) in datasets"
                :key="i"
                @click.native="onClickItem(item.id)"
              >
                <v-card
                  flat
                  height="auto"
                  :width="cardWidth"
                  class="px-4 py-4 cursor--pointer card-product-list"
                  @click="onClickBiodiversityCard(item.product_id)"
                >
                  <v-img
                    style="border-radius: 8px;background-color: #FAF8F8;"
                    width="100%"
                    :src="
                      item.image ? item.image : placeHolderImage(item.bio_type)
                    "
                    aspect-ratio="1"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex align-center justify-center transition-fast-out-slow-in v-card--reveal white--text"
                        style="height: 100%; background: rgba(0,0,0, 0.75);"
                      >
                        See Detail
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <div class="mt-3">{{ item.product_name }}</div>
                  <!-- <div style="color: #828282">
              จำนวน {{ numberWithCommas(item.total_list) }} รายการ
            </div> -->
                </v-card>
              </v-slide-item>
            </v-slide-group>
            <!-- <v-flex xs2 pa-3 v-for="(item, i) in datasets" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card
            flat
            class="card-product-list"
            @click="onClickBiodiversityCard(item.product_id)"
          >
            <v-img
              style="border-radius: 8px"
              width="100%"
              :src="item.image"
              aspect-ratio="1"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex align-center justify-center transition-fast-out-slow-in v-card--reveal white--text"
                  style="height: 100%; background: rgba(0,0,0, 0.75);"
                >
                  See Detail
                </div>
              </v-expand-transition>
            </v-img>
            <div class="mt-3">{{ item.product_name }}</div> -->
            <!-- <div style="color: #828282">
              จำนวน {{ numberWithCommas(item.total_list) }} รายการ
            </div> -->
            <!-- </v-card>
        </v-hover>
      </v-flex> -->
          </div>
          <div v-else class="text-center">
            ไม่มีข้อมูล
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    data: null,
  },
  data() {
    return {
      datasets: null,
    };
  },
  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 170;
        case "sm":
          return 170;
        case "md":
          return 265;
        case "lg":
          return 265;
        case "xl":
          return 265;
      }
      return 265;
    },
  },
  created() {
    this.datasets = JSON.parse(this.data.stat_result_json);
  },
  methods: {
    placeHolderImage(type) {
      console.log(type);
      return require(`../../assets/images/heroes/icon${type}.png`);
    },
    onClickBiodiversityCard(id) {
      console.log(id);
      this.$router.push(`/product/${id}`);
      window.location.reload();
      // this.$swal.fire({
      //   title: "Comming Soon!",
      //   text: `Todo: Get Product detail ID ${id} from API`,
      //   confirmButtonColor: "#02B25F",
      // });
    },
  },
};
</script>

<style>
.card-product-list {
  cursor: pointer;
}
.custom--slide .v-icon.v-icon {
  font-size: 30px !important;
  border: 1px solid;
  border-radius: 50%;
  background-color: #828282;
  color: #fff;
}
.custom--slide .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(228, 224, 224, 0.76) !important;
  background-color: #8282828f;
}
</style>
