import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Main from "../views/layout/Main.vue";
import Home from "../views/pages/Home.vue";
import Top10 from "../views/pages/Top10.vue";
import Product from "../views/pages/Product.vue";
import Search from "../views/pages/Search.vue";
import SpecieTable from "../views/pages/SpecieTable.vue";
import SpecieDetail from "../views/pages/SpecieDetail.vue";
import ResearchList from "../views/pages/ResearchList.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/top10/:category_id",
        name: "Top10",
        component: Top10,
      },
      {
        path: "/product/:id",
        name: "Product",
        component: Product,
      },
      {
        path: "/product-specie/:keyId",
        name: "ProductSpecie",
        component: SpecieTable,
      },
      {
        path: "/specie-detail",
        name: "SpecieDetail",
        component: SpecieDetail,
        props: (route) => ({
          ...route.params,
        }),
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/in-research/:keyId",
    name: "ResearchSearch",
    component: ResearchList,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
