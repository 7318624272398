import Service from "../Service";

const product = {
  getAllProducts() {
    return Service.get(`/api/product`);
  },
  async getProduct(id) {
    return Service.get(`/api/product/${id}`);
  },
  getProductCategory(id) {
    return Service.get(`/api/get-product-category`);
  },
  getProductSpecie(id) {
    return Service.get(`/api/get-product-specie/${id}`);
  },
  getSpecieDetail(url) {
    return Service.post(`/api/get-specie-detail`, { url });
  },
  getInResearchList(payload) {
    let path = `/api/in-research/${payload.key}`;

    if (payload.page) {
      path += `?page=${payload.page}`;
    }
    return Service.get(path);
  },
};

export default product;
