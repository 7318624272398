import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./assets/css/main.css";
import router from "./router";
import store from "./store";
import Paginate from "vuejs-paginate";

//! ===> Sweetalert 2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

//! ===> Vue Apex Charts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("paginate", Paginate);

//! ===> Vue Apex Charts
import VueNumber from "vue-number-animation";

Vue.use(VueNumber);

//! ===> Loading
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading, {
  color: "#001034",
  width: 150,
  height: 150,
  loader: "bars",
});
Vue.component("loading", Loading);

Vue.config.productionTip = false;

//* ====> Global Function
Vue.mixin({
  methods: {
    numberWithCommas(x) {
      return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
    },
    numberWithCommasAndDecimal(x) {
      return x
        ? (Math.round(x * 100) / 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : x;
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
