<template>
  <div style="height: 400px; overflow-y: auto; " class="super-slim-scroll">
    <v-layout class="justify-center">
      <v-flex>
        <v-simple-table class="super-slim-scroll">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="background-color:#052E5C; color: white;width: 140px;"
                  class="text-left"
                >
                  เลขที่ GI
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;width: 180px;"
                >
                  วันที่ลงทะเบียน GI
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  ชื่อไทย
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  ชื่ออังกฤษ
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  จังหวัด
                </th>
              </tr>
            </thead>
            <tbody class="slim-scroll">
              <tr v-for="item in giData" :key="item.name_th">
                <td style="width: 140px;">{{ item.gi_number }}</td>
                <td style="width: 180px;">{{ item.gidate }}</td>
                <td>{{ item.name_th }}</td>
                <td>{{ item.name_en }}</td>
                <td>{{ item.provinces }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="giData.length == 0" class="text-center pa-2">
          ไม่มีข้อมูล
        </div>
      </v-flex>
    </v-layout>
    <v-layout class="justify-center mt-5">
      <v-flex>
        <v-simple-table class="super-slim-scroll table-responsive">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="background-color:#052E5C; color: white;"
                  class="text-left"
                >
                  ชุมชน
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  ข้อมูลชีวภาพ
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  ประโยชน์
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  detail
                </th>
                <th
                  class="text-left"
                  style="background-color:#052E5C; color: white;"
                >
                  economic
                </th>
              </tr>
            </thead>
            <tbody class="slim-scroll">
              <tr v-for="item in productProvince" :key="item.name_th">
                <td>
                  {{ item.province_benefit.community }}
                  {{ item.province_benefit.province }}
                </td>
                <td>{{ item.province_benefit.resource }}</td>
                <td>{{ item.province_benefit.benefit }}</td>
                <td>{{ item.province_benefit.detial }}</td>
                <td>{{ item.province_benefit.econnomic_value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="productProvince.length == 0" class="text-center pa-2">
          ไม่มีข้อมูล
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    dataGraph1: null,
    dataGraph2: null,
    giData: [],
    productProvince: [],
  },
  data() {
    return {
      dataProduct: [],
      dataYearMapping: [],
      series: [],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            inverseColors: false,
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 1,
            gradientToColors: ["#56CCF2"],
            opacityTo: 1,
            stops: [0, 100, 10],
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "gray",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            formatter: function(value) {
              // return value.split(" ");
              return value;
            },
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "gray",
              offsetX: 0,
              offsetY: 0,
            },
          },
          {
            opposite: true,
            axisBorder: {
              show: true,
              color: "gray",
            },
            labels: {
              show: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    chartHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 400;
        case "lg":
          return 400;
        case "xl":
          return 400;
      }
      return 400;
    },
  },
  methods: {
    barChartExist() {
      if (this.series) {
        if (this.series[0].data) {
          if (this.series[0].data.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    tableExist() {
      if (this.series) {
        if (this.series[0].data) {
          if (this.series[0].data.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  created() {
    //*     map data graph 1
    this.series = JSON.parse(this.dataGraph1.stat_result_json).datasets;
    this.options.xaxis.categories = JSON.parse(
      this.dataGraph1.stat_result_json
    ).labels;

    this.dataProduct = JSON.parse(this.dataGraph2.stat_result_json);

    this.dataProduct.data.forEach((province) => {
      const dataIndex = this.dataYearMapping.findIndex(
        (provinceMapping) =>
          provinceMapping.province_th === province.province_th
      );

      if (dataIndex > -1) {
        this.dataYearMapping[dataIndex] = {
          ...this.dataYearMapping[dataIndex],
          [province.year]: province.total,
        };
      } else {
        this.dataYearMapping.push({
          ...province,
          [province.year]: province.total,
        });
      }
    });
  },
};
</script>

<style>
.product-list {
  max-height: 400px;
  overflow-y: scroll;
}
.product-list::-webkit-scrollbar {
  width: 4px;
}
.product-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.product-list::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 4px solid #278577;
}

.slim-scroll::-webkit-scrollbar {
  width: 4px;
}
.slim-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 2px solid #278577;
}

.super-slim-scroll::-webkit-scrollbar {
  width: 2px;
}
.super-slim-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.super-slim-scroll::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 2px solid #278577;
}

.v-data-table__wrapper::-webkit-scrollbar {
  width: 1px;
}
.v-data-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 1px solid #278577;
}
</style>
