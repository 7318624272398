<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartdata, {
      plugins: {
        legend: {
          labels: {
            defaultFontFamily: "Prompt",
            defaultFontColor: "#9a8",
          },
        },
      },
      maintainAspectRatio: false,
      // responsive: true,
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontFamily: "Prompt",
          fontStyle: "bold",
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              // stepSize: 10,
              min: 0,
              reverse: false,
              beginAtZero: true,
              callback: function(label, index, labels) {
                if (label === 0) {
                  return label;
                } else {
                  return label + "MB";
                }
              },
            },
          },
        ],
      },
    });
  },
  watch: {
    chartdata: function(val) {
      this.renderLineChart(val);
    },
  },
  methods: {
    renderLineChart(val) {
      this.renderChart(val, this.options);
    },
  },
};
</script>

<style></style>
