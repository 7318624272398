<template>
  <div>
    <v-layout class="pa-3">
      <v-flex>
        <v-row
          v-for="(item, i) in JSON.parse(data.category_stat_result_json)"
          :key="i"
          class="mb-3 listelement"
          justify="space-between"
          @click="onClickMore(item.id)"
          style="cursor:pointer;"
        >
          <v-col cols="auto">
            <span style="color: #02B25F" class="shrink mr-3">{{ i + 1 }}.</span>
            <span class="font-weight-bold">
              {{ item.product_name_th }} ( {{ item.product_name_en }} )
            </span>
          </v-col>

          <v-col cols="12" md="auto">
            <v-row justify-sm="end">
              <v-col class="my-auto" cols="12" sm="auto" style="color: #02B25F"
                >{{ numberWithCommas(item.total) }} ล้านบาท</v-col
              >
              <v-col cols="auto" class="text-sm-end">
                <v-btn depressed text class="text-capitalize px-0">
                  <v-icon color="#02B25F" small>mdi-plus-circle-outline</v-icon>
                  <span style="color: #02B25F">More</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    data: null,
  },

  methods: {
    onClickMore(id) {
      this.$router.push(`/product/${id}`);
    },
  },
};
</script>

<style>
.listelement:hover {
  background-color: #faf8f8;
}
</style>
