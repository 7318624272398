<template>
  <v-layout class="mt-6 container justify-space-around">
    <v-flex lg9 md12 sm12>
      <v-layout class="justify-center py-2">
        <v-flex class="text-center" lg9 md12 sm12>
          <span class=" font-36 text-center" style="color: #052E5C"
            >กลุ่มประเภทธุรกิจ</span
          >
        </v-flex>
      </v-layout>
      <v-layout class="justify-center py-2">
        <v-flex class="text-center" lg9 md12 sm12>
          <v-btn @click="onHandleClick(1)" color="#1877E3" dark flat rounded
            >ธุรกิจทั้งหมด</v-btn
          >
        </v-flex>
      </v-layout>
      <v-row class="justify-center" wrap>
        <v-col
          cols="auto"
          v-for="item in category"
          :key="item.id"
          class="text-center card-cate py-6"
          @click="onHandleClick(item.id)"
        >
          <div class="px-5">
            <img
              width="auto"
              height="40"
              :src="require('../../assets/images/category/cate1.png')"
              alt=""
              v-show="item.name === 'ทั้งหมด'"
            />
            <img
              width="auto"
              height="40"
              :src="require('../../assets/images/category/cate2.png')"
              alt=""
              v-show="item.name === 'สปา'"
            />
            <img
              width="auto"
              height="40"
              :src="require('../../assets/images/category/cate3.png')"
              alt=""
              v-show="item.name === 'ท่องเที่ยว'"
            />
            <img
              width="auto"
              height="40"
              :src="require('../../assets/images/category/cate4.png')"
              alt=""
              v-show="item.name === 'อาหาร และเครื่องดื่ม'"
            />
            <img
              width="auto"
              height="40"
              :src="require('../../assets/images/category/cate5.png')"
              alt=""
              v-show="item.name === 'สุขภาพ'"
            />
            <img
              width="auto"
              height="40"
              :src="require('../../assets/images/category/cate6.png')"
              alt=""
              v-show="item.name === 'เครื่องสำอาง และความงาม'"
            />
            <div
              class="font-weight-bold"
              :class="isActiveCategory == item.id && 'active'"
            >
              {{ item.name }}
            </div>
            <div
              class="font-weight-bold"
              :class="isActiveCategory == item.id && 'active'"
            >
              {{ item.count }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    onHandleClick: Function,
  },
  data() {
    return {
      category: [],
    };
  },
  async created() {
    let category = await this.getCategory();

    this.category = category.filter((c) => c.id !== 1);
  },
  computed: {
    ...mapState({
      // category: (state) => state.category.category,
      isActiveCategory: (state) => state.category.isActiveCategory,
    }),
  },
  methods: {
    ...mapActions("category", ["getCategory", "getCategoryStat"]),
  },
};
</script>

<style>
.card-cate {
  cursor: pointer;
  border-radius: 10px;
}
.active {
  color: #02b25f;
}
.card-cate:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
