<template>
  <div>
    <!-- Categories -->
    <Category :onHandleClick="onHandleClick" v-show="$route.name === 'Home'" />

    <!-- Check Data this page -->
    <div class="font-20 text-center" v-if="isHaveData === 2">
      Data not found!
    </div>
    <div v-else>
      <!-- section -->
      <section>
        <v-layout class="justify-center">
          <v-flex class="">
            <v-layout class="justify-space-between" wrap>
              <!-- Component ==> TOP 10-->
              <v-flex lg6 md6 sm12 xs12 pr-6 class="justify-center">
                <!-- Topic Card -->
                <v-layout>
                  <v-flex class="mr-2 shrink d-flex align-center top-10">
                    TOP<span style="color: #052e5c">10</span>
                  </v-flex>
                  <v-flex
                    style="width: 100%;"
                    class="shrink d-flex align-center"
                  >
                    <v-layout class="d-flex flex-column">
                      <v-flex style="font-size: 16px">มูลค่าการส่งออก</v-flex>
                      <v-flex style="font-size: 18px" class="font-weight-bold">
                        <v-row>
                          <v-col>
                            ปี 2563
                          </v-col>
                          <v-col class="text-end">
                            <v-btn
                              rounded
                              @click="top10DownloadHandler()"
                              dark
                              color="#1877E3"
                              >Download</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <div class="line"></div>

                <!-- Card Component -->
                <v-card flat class="pa-3 mt-3">
                  <Top10
                    v-if="categoryTopTenExport"
                    :data="categoryTopTenExport"
                  />
                </v-card>
              </v-flex>

              <v-divider
                :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
              ></v-divider>

              <!-- Component ==> ECONOMIC GROWTH RATE -->
              <v-flex lg6 md6 sm12 xs12 pl-6 class="justify-center">
                <!-- Topic Card -->
                <Topic
                  mainTopic="อัตราการเติบโตทางเศรษฐกิจ"
                  subTopic="ECONOMIC GROWTH RATE"
                  subTopicStyle="font-size: 24px"
                  mainTopicStyle="font-size: 32px;color: #052E5C"
                  mainClass="font-weight-bold"
                  :download="economicGrowthRateDownloadHandler"
                />
                <!-- Card Component -->
                <v-card flat class="pa-3 mt-3">
                  <EconomicGrowthRate :data="categoryEconomicGrowthRate" />
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </section>
      <!-- end section -->

      <v-divider class="my-6"></v-divider>

      <!-- section -->
      <section>
        <v-layout class="justify-center">
          <v-flex>
            <div style="font-size: 24px">ONLINE TRENDING</div>
            <div style="font-size: 32px" class="font-weight-bold">
              <v-row>
                <v-col>
                  เทรนด์การถูกพูดถึงใน
                  <span style="color: #052e5c">ออนไลน์</span>
                </v-col>
                <v-col class="text-end">
                  <v-btn
                    rounded
                    @click="onlineTrendingDownloadHandler()"
                    dark
                    color="#1877E3"
                    >Download</v-btn
                  >
                </v-col>
              </v-row>
            </div>

            <div class="line my-2"></div>

            <!-- Card Component -->
            <v-card class="pa-3 mt-3">
              <OnlineTrending
                v-if="
                  categoryOnlineTrendingByEmotion &&
                    categoryOnlineTrendingByProduct
                "
                :trendingEmotion="categoryOnlineTrendingByEmotion"
                :trendingProduct="categoryOnlineTrendingByProduct"
              />
            </v-card>

            <v-divider class="my-6"></v-divider>
          </v-flex>
        </v-layout>
      </section>
      <!-- end section -->

      <!-- section -->
      <section>
        <v-layout class="justify-center">
          <v-flex>
            <Topic
              mainTopic="ECOMMERCE SOCIAL"
              subTopic="สินค้าจากทรัพยากรธรรมชาติที่มาแรง"
              subTopicStyle="font-size: 24px"
              mainTopicStyle="font-size: 32px;color: #052E5C"
              mainClass="font-weight-bold"
            />

            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <EcommerceSocial />
            </v-card>
          </v-flex>
        </v-layout>
      </section>
      <!-- end section -->

      <!-- section -->
      <section class="mb-12">
        <v-layout class="mt-12 justify-center flex-column align-center">
          <v-flex shrink class="font-14" style="color: #02B25F">
            สนใจสินค้าชุมชน คลิ๊ก!
          </v-flex>
          <v-flex shrink mt-2 style="color: #02B25F">
            <!-- <v-btn
              style="border-radius: 20px"
              color="#02B25F"
              class="font-weight-bold"
              dark
              @click="fahsaiRedirect()"
            >
              <span class="mx-5">
                ร้านฟ้าใส แกลอรี่ By BEDO
              </span>
            </v-btn> -->
            <v-btn
              style="border-radius: 20px"
              color="#02B25F"
              class="font-weight-bold"
              dark
              @click="thaiBioShopRedirect()"
            >
              <span class="mx-5">
                Thai Bio Shop By BEDO
              </span>
            </v-btn>
          </v-flex>
        </v-layout>

        <!-- <Top10Bottom /> -->

        <v-row class="my-12" justify="center" style="position: relative;">
          <v-col
            cols="6"
            class="xs6 d-flex justify-center"
            style="max-width: 100%;"
          >
            <div class="block-top10-center">
              <div class="center">
                <div
                  class="text-center font-weight-bold"
                  style="font-size: 4rem"
                  :width="topTenCircleWith"
                >
                  TOP 10
                </div>
                <div class="text-center">
                  การจัดอันดับทรัพยากรชีวภาพ <br />
                  ที่ได้รับความนิยมในด้านต่างๆ
                </div>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            :style="
              topTenTransform
                ? ``
                : `position: absolute; left: ${topTenComponentRowOne};top: 40%`
            "
            @click="$router.push(`/top10/${categoryId}`)"
          >
            <div class="block-top10">
              <v-img
                aspect-ratio="1"
                src="../../assets/images/home/plant.png"
                alt=""
              />
            </div>
            <div class="text-center">
              Export
            </div>
            <div class="text-center">
              ข้อมูลการส่งออก
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            :style="
              topTenTransform
                ? ``
                : `position: absolute; right: ${topTenComponentRowOne};top: 40%`
            "
            @click="$router.push(`/top10/${categoryId}`)"
          >
            <div class="block-top10">
              <v-img
                aspect-ratio="1"
                src="../../assets/images/home/Note.png"
                alt=""
              />
            </div>
            <div class="text-center">
              Research
            </div>
            <div class="text-center">
              ข้อมูลงานวิจัย
            </div>
          </v-col>
        </v-row>

        <v-row mt-12 justify-center style="position: relative">
          <v-col
            cols="12"
            md="3"
            :style="
              topTenTransform ? `` : `position: absolute; left: 25%;top: -50px`
            "
            @click="$router.push(`/top10/${categoryId}`)"
          >
            <div class="block-top10">
              <v-img
                aspect-ratio="1"
                src="../../assets/images/home/Globe.png"
                alt=""
              />
            </div>
            <div class="text-center">
              Social Network
            </div>
            <div class="text-center">
              ข้อมูลสื่อสังคมทางออนไลน์
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
            :style="
              topTenTransform
                ? ``
                : `position: absolute; right: 27.3%;top: -50px`
            "
            @click="$router.push(`/top10/${categoryId}`)"
          >
            <div class="block-top10">
              <v-img
                aspect-ratio="1"
                src="../../assets/images/home/Package.png"
                alt=""
              />
            </div>
            <div class="text-center">
              Product
            </div>
            <div class="text-center">
              ข้อมูลผลการผลิต
            </div>
          </v-col>
        </v-row>
      </section>
      <!-- end section -->
    </div>
  </div>
</template>

<script>
import EcommerceSocial from "../../components/home/EcommerceSocial.vue";
import EconomicGrowthRate from "../../components/home/EconomicGrowthRate.vue";
import OnlineTrending from "../../components/home/OnlineTrending.vue";
import Top10 from "../../components/home/Top10.vue";
import Topic from "../../components/Topic.vue";
import { mapState, mapActions } from "vuex";
import Category from "../layout/Category.vue";
import { domain } from "../../services/Constants";

export default {
  components: {
    Top10,
    EconomicGrowthRate,
    OnlineTrending,
    EcommerceSocial,
    Topic,
    Category,
  },
  data() {
    return {
      selectedId: null,
    };
  },
  created() {
    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });
    const id = this.$route.params.id ? this.$route.params.id : 1;
    this.selectedId = id;
    this.getCategoryStat({ id: id })
      .then(() => loader.hide())
      .catch((err) => console.log(err));
  },
  computed: {
    ...mapState({
      isHaveData: (state) => state.category.isHaveData,
      categoryOnlineTrendingByProduct: (state) =>
        state.category.categoryOnlineTrendingByProduct,
      categoryOnlineTrendingByEmotion: (state) =>
        state.category.categoryOnlineTrendingByEmotion,
      categoryTopTenExport: (state) => state.category.categoryTopTenExport,
      categoryEconomicGrowthRate: (state) =>
        state.category.categoryEconomicGrowthRate,
      categoryId: (state) => state.category.isActiveCategory,
    }),
    topTenCircleWith() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 100;
        case "md":
          return 100;
        case "lg":
          return 300;
        case "xl":
          return 300;
      }
      return 300;
    },
    topTenComponentRowOne() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 0;
        case "md":
          return "5%";
        case "lg":
          return "5%";
        case "xl":
          return "15%";
      }
      return "15%";
    },
    topTenTransform() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return false;
    },
  },
  methods: {
    thaiBioShopRedirect() {
      window.open("https://thaibioshop.com/");
    },
    fahsaiRedirect() {
      window.open("http://www.fahsaigallery.com/");
    },
    ...mapActions("category", ["getCategoryStat"]),
    onHandleClick(id) {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.selectedId = id;
      this.getCategoryStat({ id: id }).then(() => {
        loader.hide();
      });
    },
    top10DownloadHandler() {
      window.open(
        `${domain}excel-category-stat/top10?categoryId=${this.selectedId || 1}`
      );
    },
    economicGrowthRateDownloadHandler() {
      window.open(
        `${domain}excel-category-stat/economic?categoryId=${this.selectedId ||
          1}`
      );
    },
    onlineTrendingDownloadHandler() {
      window.open(
        `${domain}excel-category-stat/onlinetrending?categoryId=${this
          .selectedId || 1}`
      );
    },
  },
};
</script>

<style>
.top-10 {
  font-size: 56px;
  font-weight: bold;
}
.line {
  height: 2px;
  background-color: #052e5c;
}
.block-top10-center {
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 450px;
  height: 450px;
  box-shadow: 0px 5px 10px 1px rgb(104, 187, 235);
  background: linear-gradient(180deg, #1877e3 0%, #052e5c 100%);
  border: 40px solid #fff;

  box-shadow: 0 0 0 5px #052e5c;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.block-top10 {
  top: 0;
  margin: 0 auto;
  left: 0;
  padding: 2rem;
  border-radius: 50%;
  width: 125px;
  background-color: #052e5c;
  transition-property: width;
  transition-duration: 0.5s;
}
.block-top10:hover {
  box-shadow: 0px 5px 10px 1px rgb(104, 187, 235);
  cursor: pointer;
  width: 140px;
  background-color: #1062c0;
}
</style>
