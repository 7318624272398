<template>
  <div>
    <v-row class="justify-start">
      <v-col
        cols="12"
        md="6"
        xs12
        md6
        v-if="inChartExist()"
        class="justify-center"
      >
        <v-layout>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-flex xs11 pl-md-12 class="font-12 font-weight-bold">
            จำนวนงานวิจัย
            <div style="color: gray">
              (ที่ตีพิมพ์)
            </div>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-flex xs10>
            <apexchart
              :height="chartHeight"
              type="bar"
              :options="inOptions"
              :series="inSeries"
            ></apexchart>
          </v-flex>
          <v-flex
            xs1
            class="d-flex align-end font-12 font-weight-bold pl-3"
            style="padding-bottom: 4rem"
          >
            ปี พ.ศ.
          </v-flex>
        </v-layout>
        <div class="text-center">
          จำนวนวิจัยในประเทศ
        </div>
        <div
          class="mx-5 mt-2 pa-4"
          style="background-color: #FAF8F8; cursor: pointer;"
          @click="inResearchRidirectHandler()"
        >
          <div>
            <span class="font-weight-bold">
              รายงานวิจัยกระเทียมที่เกี่ยวข้องในประเทศ
            </span>
          </div>
          <div>
            <span>
              ThaiJournal Online (ThaiJO)
            </span>
          </div>
        </div>

        <!-- <v-layout mt-2 justify-space-around>
          <v-spacer></v-spacer>
          <v-flex mr-8 shrink class="font-12 d-flex align-center flex-row">
            <span
              style="width: 15px;height: 15px;background: linear-gradient(180deg, #1877E3 0%, #56CCF2 100%);"
            ></span>
            <span class="ml-3">
              จำนวนวิจัยในประเทศ
            </span>
          </v-flex>
          <v-flex ml-7 shrink class="font-12 d-flex align-center flex-row">
            <span
              style="width: 15px;height: 15px;background: linear-gradient(180deg, #02B25F 0%, #C4FB79 100%);"
            ></span>
            <span class="ml-3">
              จำนวนวิจัยต่างประเทศ
            </span>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout> -->
      </v-col>
      <v-col cols="12" md="6" xs12 md6 v-else class="ma-auto text-center">
        ไม่มีข้อมูล
      </v-col>

      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
      ></v-divider>

      <v-col
        cols="12"
        md="6"
        xs12
        md6
        v-if="outChartExist()"
        class="justify-center"
      >
        <v-layout>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-flex xs11 pl-md-12 class="font-12 font-weight-bold">
            จำนวนงานวิจัย
            <div style="color: gray">
              (ที่ตีพิมพ์)
            </div>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-flex xs10>
            <apexchart
              :height="chartHeight"
              type="bar"
              :options="outOptions"
              :series="outSeries"
            ></apexchart>
          </v-flex>
          <v-flex
            xs1
            class="d-flex align-end font-12 font-weight-bold pl-3"
            style="padding-bottom: 4rem"
          >
            ปี พ.ศ.
          </v-flex>
        </v-layout>
        <div class="text-center">
          จำนวนวิจัยต่างประเทศ
        </div>
        <div
          class="mx-5 mt-2 pa-4"
          style="background-color: #FAF8F8; cursor: pointer;"
          @click="outResearchRidirectHandler()"
        >
          <div>
            <span class="font-weight-bold">
              รายงานวิจัยกระเทียมที่เกี่ยวข้องต่างประเทศ
            </span>
          </div>
          <div>
            <span>
              ScienceDirect
            </span>
          </div>
        </div>

        <!-- <v-layout mt-2 justify-space-around>
          <v-spacer></v-spacer>
          <v-flex mr-8 shrink class="font-12 d-flex align-center flex-row">
            <span
              style="width: 15px;height: 15px;background: linear-gradient(180deg, #1877E3 0%, #56CCF2 100%);"
            ></span>
            <span class="ml-3">
              จำนวนวิจัยในประเทศ
            </span>
          </v-flex>
          <v-flex ml-7 shrink class="font-12 d-flex align-center flex-row">
            <span
              style="width: 15px;height: 15px;background: linear-gradient(180deg, #02B25F 0%, #C4FB79 100%);"
            ></span>
            <span class="ml-3">
              จำนวนวิจัยต่างประเทศ
            </span>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout> -->
      </v-col>
      <v-col cols="12" md="6" xs12 md6 v-else class="ma-auto text-center">
        ไม่มีข้อมูล
      </v-col>
    </v-row>

    <!-- <v-layout class="mt-12 justify-center align-center">
      <v-flex shrink mt-2 style="color: #02B25F">
        <v-btn
          style="border-radius: 20px"
          color="#02B25F"
          class="font-weight-bold"
          dark
        >
          <span class="mx-12">
            ดูงานวิจัยทั้งหมด
          </span>
        </v-btn>
      </v-flex>
    </v-layout> -->
  </div>
</template>

<script>
export default {
  props: {
    inData: null,
    outData: null,
    productName: null,
    productNameFull: null,
    keyId: null,
  },
  data() {
    return {
      inSeries: [],
      outSeries: [],
      inOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            dataLabels: {
              position: "top",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            inverseColors: false,
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 1,
            gradientToColors: ["#C4FB79", "#02B25F"],
            opacityTo: 1,
            stops: [0, 100, 10],
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        chart: {
          toolbar: {
            show: false,
          },
        },

        legend: {
          show: true,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "gray",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "gray",
              offsetX: 0,
              offsetY: 0,
            },
          },
          // {
          //   opposite: true,
          //   axisBorder: {
          //     show: true,
          //     color: "gray",
          //   },
          //   labels: {
          //     show: false,
          //   },
          // },
        ],

        tooltip: {
          shared: true,
          intersect: false,
        },
      },
      outOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            dataLabels: {
              position: "top",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            inverseColors: false,
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 1,
            gradientToColors: ["#56CCF2", "#1877E3", "#56CCF2"],
            opacityTo: 1,
            stops: [0, 100, 10],
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        chart: {
          toolbar: {
            show: false,
          },
        },

        legend: {
          show: true,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "gray",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "gray",
              offsetX: 0,
              offsetY: 0,
            },
          },
          // {
          //   opposite: true,
          //   axisBorder: {
          //     show: true,
          //     color: "gray",
          //   },
          //   labels: {
          //     show: false,
          //   },
          // },
        ],

        tooltip: {
          shared: true,
          intersect: false,
        },
      },
      //   series: [],
      //   options: {
      //     chart: {
      //       type: "bar",
      //     },
      //     plotOptions: {
      //       bar: {
      //         horizontal: false,
      //         dataLabels: {
      //           position: "top",
      //         },
      //       },
      //     },
      //     dataLabels: {
      //       enabled: false,
      //       style: {
      //         fontSize: "12px",
      //         colors: ["#fff"],
      //       },
      //     },
      //     stroke: {
      //       show: true,
      //       width: 1,
      //       colors: ["#fff"],
      //     },
      //     tooltip: {
      //       shared: true,
      //       intersect: false,
      //     },
      //     xaxis: {
      //       categories: [],
      //       axisBorder: {
      //         show: true,
      //         color: "gray",
      //         height: 1,
      //         offsetX: 0,
      //         offsetY: 0,
      //       },
      //     },
      //     yaxis: {
      //       categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
      //       axisBorder: {
      //         show: true,
      //         color: "gray",
      //         offsetX: 0,
      //         offsetY: 0,
      //       },
      //     },

      //     fill: {
      //       type: "gradient",
      //       gradient: {
      //         inverseColors: false,
      //         type: "vertical",
      //         shadeIntensity: 1,
      //         opacityFrom: 1,
      //         gradientToColors: ["#6B6FD9", "#C4FB79"],
      //         opacityTo: 1,
      //         stops: [0, 100, 10],
      //       },
      //     },
      //   },
    };
  },
  computed: {
    chartHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 500;
        case "lg":
          return 500;
        case "xl":
          return 500;
      }
      return 500;
    },
  },
  created() {
    //*     map data graph 1
    this.inSeries = JSON.parse(this.inData.stat_result_json).datasets;
    // this.series[0]["name"] = "จำนวนวิจัยในประเทศ";
    // this.series[1]["name"] = "จำนวนวิจัยต่างประเทศ ";
    this.inOptions.xaxis.categories = JSON.parse(
      this.inData.stat_result_json
    ).labels;

    //*     map data graph 2
    this.outSeries = JSON.parse(this.outData.stat_result_json).datasets;
    // this.series[0]["name"] = "จำนวนวิจัยในประเทศ";
    // this.series[1]["name"] = "จำนวนวิจัยต่างประเทศ ";
    this.outOptions.xaxis.categories = JSON.parse(
      this.outData.stat_result_json
    ).labels;

    console.log(this.outSeries);
  },
  methods: {
    outResearchRidirectHandler() {
      window.open(
        `http://sciencedirect.ksta.co?qs=${this.productName.toLowerCase()}`
      );
    },
    inResearchRidirectHandler() {
      this.$router.push({
        path: `/in-research/${this.keyId}`,
        query: { productName: this.productNameFull },
      });
    },
    inChartExist() {
      if (this.inSeries) {
        if (this.inSeries[0].data) {
          if (this.inSeries[0].data.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    outChartExist() {
      if (this.outSeries) {
        if (this.outSeries[0].data) {
          if (this.outSeries[0].data.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
