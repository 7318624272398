<template>
  <div>
    <div class="text-end font-14">หน่วย: {{ data.unit }}</div>
    <v-layout class="pa-3 pr-0">
      <v-flex>
        <v-layout v-for="(item, i) in data.data" :key="i" class="mb-3">
          <v-flex style="color: #828282" class="shrink mr-3">
            {{ i + 1 }}.
          </v-flex>
          <v-flex class="font-weight-bold">
            {{ item.product_name_th }} ( {{ item.product_name_en }} )
          </v-flex>
          <v-flex class=" text-end">
            <span style="color: #1877E3">
              {{
                item.total
                  ? data.unit === "ล้านบาท"
                    ? numberWithCommasAndDecimal(item.total)
                    : numberWithCommas(item.total)
                  : "---"
              }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    data: null,
    unit: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style></style>
