<template>
  <v-container> </v-container>
</template>

<script>
export default {
  name: "SpecieDetail",
  props: ["url"],
  data() {
    return {
      headers: [{ text: "Science Name", value: "sciName" }],
      species: [],
    };
  },
  async mounted() {
    console.log(this.url);
    let response = await this.$store.dispatch(
      "product/getSpecieDetail",
      this.url
    );

    console.log(response);

    // this.species = response.data.items;
  },
  methods: {
    onClickRow(item) {
      console.log(item);
    },
  },
};
</script>
