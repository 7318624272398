<template>
  <v-container
    style="height: 400px; overflow-y: auto; "
    class="super-slim-scroll"
  >
    <v-row style="width:100%;" justify="center" class="justify-center ma-0">
      <v-col cols="12" class="pa-0">
        <div class="text-center py-5 font-weight-bold">
          ข้อมูลผลผลิตจาก สำนักงานเศรษฐกิจการเกษตร
        </div>
      </v-col>
      <v-col cols="12">
        <v-row class="justify-space-between">
          <!-- Graph 1 -->
          <v-col
            v-if="barChartExist()"
            sm="12"
            cols="12"
            class="justify-center pa-0"
          >
            <v-row>
              <v-col class="py-0">
                <div class="font-12 font-weight-bold">
                  (จำนวนผลผลิต)
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="11" class="pa-0">
                <apexchart
                  :height="chartHeight"
                  type="bar"
                  :options="options"
                  :series="series"
                ></apexchart>
              </v-col>
              <v-col
                class="d-flex align-end font-12 ml-n4 pb-12 font-weight-bold pa-0"
              >
                ปี พ.ศ.
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else class="ma-auto text-center pa-0">
            ไม่มีข้อมูล
          </v-col>

          <!-- Graph 2 -->
          <v-col
            v-if="dataYearMapping.length > 0"
            cols="12"
            class="justify-center pa-0"
          >
            <!-- <div class="text-center font-24 font-weight-bold">
              {{ dataGraph2.stat_description }}
            </div> -->
            <v-simple-table class="super-slim-scroll">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      style="background-color:#052E5C; color: white;"
                      class="text-left"
                    >
                      จังหวัด
                    </th>
                    <th
                      v-for="(yearLabel, i) in dataProduct.labels"
                      class="text-left"
                      :key="i"
                      style="background-color:#052E5C; color: white;"
                    >
                      {{ yearLabel }}
                    </th>
                  </tr>
                </thead>
                <tbody class="slim-scroll">
                  <tr v-for="item in dataYearMapping" :key="item.name">
                    <td>{{ item.province_th }}</td>
                    <td v-for="(yearLabel, i) in dataProduct.labels" :key="i">
                      {{ item[yearLabel] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- <div class="product-list pa-3">
              <v-layout
                class="my-6"
                v-for="(item, i) in dataProduct.data"
                :key="i"
              >
                <v-flex xs6>
                  <div>{{ item.province_th }}</div>
                  <div style="color: #828282">{{ item.province_en }}</div>
                </v-flex>
                <v-flex
                  xs3
                  style="color: #828282"
                  class="d-flex align-end justify-end"
                >
                  {{ item.year }}:
                </v-flex>
                <v-flex xs3 class="d-flex align-end justify-end">
                  {{ numberWithCommas(item.total) }} ตัน
                </v-flex>
              </v-layout>
            </div> -->
          </v-col>
          <v-col v-else class="ma-auto text-center pa-0">
            ไม่มีข้อมูล
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    dataGraph1: null,
    dataGraph2: null,
  },
  data() {
    return {
      dataProduct: [],
      dataYearMapping: [],
      series: [],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            inverseColors: false,
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 1,
            gradientToColors: ["#56CCF2"],
            opacityTo: 1,
            stops: [0, 100, 10],
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "gray",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            formatter: function(value) {
              // return value.split(" ");
              return value;
            },
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "gray",
              offsetX: 0,
              offsetY: 0,
            },
          },
          {
            opposite: true,
            axisBorder: {
              show: true,
              color: "gray",
            },
            labels: {
              show: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    chartHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 200;
        case "md":
          return 200;
        case "lg":
          return 200;
        case "xl":
          return 200;
      }
      return 200;
    },
  },
  methods: {
    barChartExist() {
      if (this.series) {
        if (this.series[0].data) {
          if (this.series[0].data.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    tableExist() {
      if (this.series) {
        if (this.series[0].data) {
          if (this.series[0].data.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  created() {
    //*     map data graph 1
    this.series = JSON.parse(this.dataGraph1.stat_result_json).datasets;
    this.options.xaxis.categories = JSON.parse(
      this.dataGraph1.stat_result_json
    ).labels;

    this.dataProduct = JSON.parse(this.dataGraph2.stat_result_json);

    this.dataProduct.data.forEach((province) => {
      const dataIndex = this.dataYearMapping.findIndex(
        (provinceMapping) =>
          provinceMapping.province_th === province.province_th
      );

      if (dataIndex > -1) {
        this.dataYearMapping[dataIndex] = {
          ...this.dataYearMapping[dataIndex],
          [province.year]: province.total,
        };
      } else {
        this.dataYearMapping.push({
          ...province,
          [province.year]: province.total,
        });
      }
    });
  },
};
</script>

<style>
.product-list {
  max-height: 400px;
  overflow-y: scroll;
}
.product-list::-webkit-scrollbar {
  width: 4px;
}
.product-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.product-list::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 4px solid #278577;
}

.slim-scroll::-webkit-scrollbar {
  width: 4px;
}
.slim-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 2px solid #278577;
}

.super-slim-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.super-slim-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.super-slim-scroll::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 2px solid #278577;
}

.v-data-table__wrapper::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}
.v-data-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 1px solid #278577;
}
</style>
