<template>
  <div class="mt-10">
    <!-- <div class="text-center font-22" v-if="!product">
      Data Not Found!
    </div> -->
    <div>
      <!-- Product Detail -->
      <section>
        <v-layout class="justify-center">
          <v-flex class="">
            <v-layout class="justify-space-between" wrap>
              <v-flex lg4 md4 sm12 xs12 pr-md-6 class="justify-center">
                <Topic
                  :mainTopic="product.name_en"
                  :subTopic="product.name_th"
                  subTopicStyle="font-size: 24px"
                  mainTopicStyle="font-size: 32px;color: #052E5C"
                  mainClass="font-weight-bold"
                />

                <v-img
                  class="mt-6"
                  aspect-ratio="1"
                  style="background-color: #FAF8F8"
                  contain
                  :src="
                    product.image
                      ? product.image
                      : placeHolderImage(product.bio_type)
                  "
                  alt=""
                />
                <v-row>
                  <v-col cols="auto" class="my-auto">
                    <span class="font-22">
                      ดูรายการสายพันธุ์ของ{{ product.name_th }}
                    </span>
                  </v-col>
                  <v-col cols="auto" class="my-auto">
                    <v-btn
                      height="30"
                      style="min-width: 0;margin-top: 0;background-color: #15B86B; border-radius: 50px; color:white; "
                      class="mb-1 pa-1"
                      @click="specieRedirect"
                    >
                      <v-icon>
                        mdi-magnify
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>

              <v-flex lg8 md8 sm12 xs12 pl-md-6 class="justify-center">
                <Topic
                  mainTopic="ทรัพยากร"
                  subTopic="รายละเอียด"
                  subTopicStyle="font-size: 24px"
                  mainTopicStyle="font-size: 32px;color: #052E5C"
                  mainClass="font-weight-bold"
                />
                <v-card flat class="mt-6 product-box">
                  <div v-html="product.description"></div>
                  <br />
                  <div v-html="product.product_detail_json"></div>
                  <br />
                  <div>
                    <span class="font-weight-bold">
                      สารสำคัญที่ถูกอ้างอิง
                    </span>
                    <div class="py-2">
                      <span>
                        {{
                          product.product_matter
                            ? product.product_matter.chemotype_th.replaceAll(
                                '"',
                                ""
                              )
                            : ""
                        }}
                      </span>
                    </div>
                    <div class="py-2">
                      <span>
                        {{
                          product.product_matter
                            ? product.product_matter.chemotype_en.replaceAll(
                                '"',
                                ""
                              )
                            : ""
                        }}
                      </span>
                    </div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </section>

      <v-divider class="mt-4 mb-6"></v-divider>

      <!-- Market Value -->
      <section>
        <v-layout class="justify-center">
          <v-flex>
            <Topic
              mainTopic="มูลค่าการตลาด"
              subTopic="MARKET VALUE"
              subTopicStyle="font-size: 24px"
              mainTopicStyle="font-size: 32px;color: #052E5C"
              mainClass="font-weight-bold"
              :download="marketValueDownloadHandler"
            />
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <MarketValue
                :dataGraph1="dataGraph1"
                :dataGraph2="dataGraph2"
                :dataGraph3="dataGraph3"
                :dataGraph4="dataGraph4"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </section>

      <v-divider class="mt-4 mb-6"></v-divider>

      <!-- ONLINE TRENDING -->
      <section>
        <v-layout class="justify-center">
          <v-flex>
            <v-layout>
              <v-flex class="mr-2 shrink" style="width:100%">
                <div style="font-size: 24px">
                  ONLINE TRENDING
                </div>
                <div>
                  <v-row>
                    <v-col>
                      <div style="font-size: 32px;" class="font-weight-bold">
                        เทรนด์การถูกพูดถึงใน
                        <span style="color: #052E5C">ออนไลน์</span>
                      </div>
                    </v-col>
                    <v-col class="text-end">
                      <v-btn
                        rounded
                        @click="onlineTrendingDownloadHandler()"
                        dark
                        color="#1877E3"
                        >Download</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-flex>
            </v-layout>
            <div class="line"></div>
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <OnlineTrendingProduct
                :dataGraph1="dataOnlineTrendingGraph1"
                :dataGraph2="
                  dataOnlineTrendingGraph2
                    ? JSON.parse(dataOnlineTrendingGraph2.stat_result_json)
                    : []
                "
              />
            </v-card>
          </v-flex>
        </v-layout>
      </section>

      <v-divider class="mt-4 mb-6"></v-divider>

      <!-- ONLINE TRENDING -->
      <section>
        <v-layout class="justify-center">
          <v-flex>
            <v-layout>
              <v-flex class="mr-2 shrink">
                <div style="font-size: 24px">
                  สินค้าออนไลน์
                </div>
                <div style="font-size: 32px;" class="font-weight-bold">
                  ECOMMERCE SOCIAL
                </div>
              </v-flex>
            </v-layout>
            <div class="line"></div>
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <EcommerceSocialProduct
                :dataGraph1="dataEcommerceSocialGraph1"
                :dataGraph2="dataEcommerceSocialGraph2"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </section>

      <v-divider class="mt-4 mb-6"></v-divider>

      <!-- RELATED RESEARCH -->
      <section>
        <v-layout mb-12 class="justify-center">
          <v-flex>
            <Topic
              mainTopic="RELATED RESEARCH"
              subTopic="งานวิจัยที่เกี่ยวข้อง"
              subTopicStyle="font-size: 24px"
              mainTopicStyle="font-size: 32px;color: #052E5C"
              mainClass="font-weight-bold"
              :download="relatedResearchDownloadHandler"
            />
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <RelateResearch
                :inData="dataRelateResearch1"
                :outData="dataRelateResearch2"
                :productName="product.name_en"
                :productNameFull="`${product.name_th} - (${product.name_en})`"
                :keyId="product.key_id"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </section>

      <!-- GEOGRAPHICAL INDICATIONS -->
      <section>
        <v-layout class="justify-center" wrap>
          <v-flex md12 xs12 sm12>
            <Topic
              mainTopic="GEOGRAPHICAL INDICATIONS"
              subTopic="สิ่งบ่งชี้ทางภูมิศาสตร์"
              subTopicStyle="font-size: 24px"
              mainTopicStyle="font-size: 32px;color: #052E5C"
              mainClass="font-weight-bold"
            />
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <Geographical
                :dataGraph1="dataGeographicalGraph1"
                :dataGraph2="dataGeographicalGraph2"
                :giData="giData"
                :productProvince="productProvince"
                data="gi"
              />
            </v-card>
          </v-flex>
          <v-flex md12 xs12 sm12>
            <Topic
              mainTopic="PRODUCT"
              subTopic="ข้อมูลผลผลิตทรัพยากรชีวภาพ"
              subTopicStyle="font-size: 24px"
              mainTopicStyle="font-size: 32px;color: #052E5C"
              mainClass="font-weight-bold"
              :download="geographicalDownloadHandler"
            />
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <GeographicalProduct
                :dataGraph1="dataGeographicalGraph1"
                :dataGraph2="dataGeographicalGraph2"
                data="graph"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </section>

      <!-- BIODIVERSITY RESOURCE -->
      <section>
        <v-layout class="justify-center">
          <v-flex>
            <Topic
              mainTopic="BIODIVERSITY RESOURCE"
              subTopic="ทรัพยากรชีวภาพที่เกี่ยวข้อง"
              subTopicStyle="font-size: 24px"
              mainTopicStyle="font-size: 32px;color: #052E5C"
              mainClass="font-weight-bold"
            />
            <!-- Card Component -->
            <v-card flat class="pa-3 mt-3">
              <Biodeversity :data="dataBiodiversity" />
            </v-card>
          </v-flex>
        </v-layout>
      </section>
    </div>
  </div>
</template>

<script>
import Topic from "../../components/Topic.vue";
import Biodeversity from "../../components/product/Biodeversity.vue";
import Geographical from "../../components/product/Geographical.vue";
import GeographicalProduct from "../../components/product/GeographicalProduct.vue";
import MarketValue from "../../components/product/MarketValue.vue";
import RelateResearch from "../../components/product/RelateResearch.vue";
import OnlineTrendingProduct from "../../components/product/OnlineTrendingProduct.vue";
import EcommerceSocialProduct from "../../components/product/EcommerceSocialProduct.vue";
import { domain } from "../../services/Constants";

export default {
  components: {
    Topic,
    Biodeversity,
    Geographical,
    GeographicalProduct,
    MarketValue,
    RelateResearch,
    OnlineTrendingProduct,
    EcommerceSocialProduct,
  },
  data() {
    return {
      product_id: null,
      product: null,

      //* Market Value
      dataGraph1: null,
      dataGraph2: null,
      dataGraph3: null,
      dataGraph4: null,

      //* ONLINE TRENDING
      dataOnlineTrendingGraph1: null,
      dataOnlineTrendingGraph2: null,

      //* ECOMMERCE SOCIAL
      dataEcommerceSocialGraph1: null,
      dataEcommerceSocialGraph2: null,

      //* GEOGRAPHICAL INDICATIONS
      dataGeographicalGraph1: null,
      dataGeographicalGraph2: null,
      giData: [],
      productProvince: [],

      //* ELATED RESEARCH
      dataRelateResearch1: null,
      dataRelateResearch2: null,

      //* BIODIVERSITY RESOURCE
      dataBiodiversity: null,
    };
  },
  created() {
    this.product_id = this.$route.params.id;
    this.getProduct(this.product_id);
  },
  methods: {
    placeHolderImage(type) {
      console.log(type);
      return require(`../../assets/images/heroes/icon${type}.png`);
    },
    specieRedirect() {
      this.$router.push(`/product-specie/${this.product.key_id}`);
    },
    geographicalDownloadHandler() {
      window.open(
        `${domain}excel-product-stat/${this.product_id}/geographical`
      );
    },
    marketValueDownloadHandler() {
      window.open(`${domain}excel-product-stat/${this.product_id}/marketvalue`);
    },
    onlineTrendingDownloadHandler() {
      window.open(
        `${domain}excel-product-stat/${this.product_id}/onlinetrendingdetail`
      );
    },
    relatedResearchDownloadHandler() {
      window.open(`${domain}excel-product-stat/${this.product_id}/research`);
    },
    getProduct(id) {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.$store
        .dispatch("product/getProduct", id)
        .then((res) => {
          // * ==>  map data
          this.product = res.data;

          console.log(this.product);

          this.dataGraph1 = res.data.productState.find(
            (item) => item.stat_name_id === 1
          );
          this.dataGraph2 = res.data.productState.find(
            (item) => item.stat_name_id === 2
          );
          this.dataGraph3 = res.data.productState.find(
            (item) => item.stat_name_id === 3
          );
          this.dataGraph4 = res.data.productState.find(
            (item) => item.stat_name_id === 4
          );
          this.dataOnlineTrendingGraph1 = res.data.productState.find(
            (item) => item.stat_name_id === 5
          );
          this.dataOnlineTrendingGraph2 = res.data.productState.find(
            (item) => item.stat_name_id === 6
          );
          this.dataEcommerceSocialGraph1 = res.data.productState.find(
            (item) => item.stat_name_id === 7
          );
          this.dataEcommerceSocialGraph2 = res.data.productState.find(
            (item) => item.stat_name_id === 8
          );
          this.dataGeographicalGraph1 = res.data.productState.find(
            (item) => item.stat_name_id === 9
          );
          this.dataGeographicalGraph2 = res.data.productState.find(
            (item) => item.stat_name_id === 10
          );
          this.dataRelateResearch1 = res.data.productState.find(
            (item) => item.stat_name_id === 11
          );
          this.dataRelateResearch2 = res.data.productState.find(
            (item) => item.stat_name_id === 13
          );
          this.dataBiodiversity = res.data.productState.find(
            (item) => item.stat_name_id === 12
          );

          this.giData = res.data.giData;

          console.log(res.data);

          this.productProvince = res.data.productProvince;

          loader.hide();
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
        });
    },
  },
};
</script>

<style>
.product-box {
  max-height: 450px;
  overflow-y: scroll;
}

.product-box::-webkit-scrollbar {
  width: 4px;
}
.product-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.product-box::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 4px solid #278577;
}

.slim-scroll::-webkit-scrollbar {
  width: 4px;
}
.slim-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 4px solid #278577;
}
</style>
