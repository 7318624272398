<script>
import { Line, mixins } from "vue-chartjs";
export default {
  extends: Line,
  data() {
    return {
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 14,
                fontFamily: "Prompt",
                fontStyle: "bold",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                stepSize: 55,
                min: 0,
                reverse: false,
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        elements: {
          line: {
            tension: 0,
          },
        },
      },
    };
  },
  props: ["data"],
  mounted() {
    // this.renderChart(this.data, this.options);
    this.renderChart(this.data, this.options);
  },
};
</script>

<style scoped></style>
