<template>
  <div class="bg-heroes">
    <v-layout class="d-flex justify-center">
      <v-flex shrink>
        <img width="150" src="../../assets/images/logo.png" alt="" />
      </v-flex>
    </v-layout>
    <v-layout class="text-center d-flex justify-center">
      <v-flex shrink>
        <span style="font-size: 36px;color: #fff;font-weight: bold; top: 40%">
          Bioresource & Bioeconomy Trend
        </span>
      </v-flex>
    </v-layout>

    <v-layout class="mt-12 container justify-center">
      <v-flex lg9 md12 sm12>
        <v-row class="d-flex justify-space-around">
          <v-flex
            class="my-5"
            shrink
            v-for="item in productCategoryList"
            :key="item.id"
          >
            <v-layout class="d-flex justify-center">
              <v-flex class="d-flex justify-center shrink">
                <!-- พืช -->
                <v-row>
                  <v-col md="6" cols="12" class="text-center text-md-left">
                    <img width="70" height="70" :src="item.logoUrl" alt="" />
                  </v-col>
                  <v-col
                    md="6"
                    cols="12"
                    style="color: white;"
                    class="text-center text-md-left"
                  >
                    <div>{{ item.product_category_name_th }}</div>
                    <div class="font-36 font-weight-bold text-bedo-green">
                      <number
                        ref="number1"
                        :from="0"
                        :to="item.total_amount"
                        :format="theFormat"
                        :duration="3"
                        :delay="2"
                        easing="Power1.easeInOut"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-flex>

              <!-- <v-flex class="d-flex justify-center shrink ml-4">
                <v-layout class="d-flex justify-center" style="color: #fff">
                  <v-flex class="shrink">
                    <div>{{ item.product_category_name_th }}</div>
                    <div class="font-36 font-weight-bold text-bedo-green">
                      <number
                        ref="number1"
                        :from="0"
                        :to="item.total_amount"
                        :format="theFormat"
                        :duration="3"
                        :delay="2"
                        easing="Power1.easeInOut"
                      />
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex> -->
            </v-layout>
          </v-flex>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    // ...mapState({
    //   productCategoryList: (state) => state.product.productCategoryList,
    // }),
  },
  data() {
    return {
      productCategoryList: [],
    };
  },
  async created() {
    this.productCategoryList = await this.getProductCategory();
    this.productCategoryList = this.productCategoryList.map((categoryList) => ({
      ...categoryList,
      logoUrl: require(`../../assets/images/heroes/icon${categoryList.id}.png`),
    }));

    console.log(this.productCategoryList);
  },
  mounted() {},
  methods: {
    ...mapActions("product", ["getProductCategory"]),

    theFormat(number) {
      return this.numberWithCommas(number.toFixed());
    },
  },
};
</script>

<style>
.bg-heroes {
  padding: 3rem 0px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/heroes.png");
  background-repeat: round;
  background-attachment: fixed;
  /* background-size: cover; */
}
.align--center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
