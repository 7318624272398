<template>
  <div>
    <ul style="color: #828282" v-if="$route.name === 'Home'" class="mx-5 my-5 ">
      <li>
        <span>
          การแสดงกราฟมูลค่าการนำเข้าและส่งออกของประเทศ : การค้าไทย
        </span>
      </li>
      <li>
        <span>
          การแสดงกราฟผลิตภัณฑ์มวลรวบในประเทศ : ธนาคารแห่งประเทศไทย
        </span>
      </li>
      <li>
        <span>
          กราฟแสดงจำนวนโพสต์ : Facebook
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip }"
              >mdi-information-outline Google @Google
            </v-icon>
          </template>
          <div>
            ไทยรัฐ, องค์การเภสัชกรรม, NSTDA - สวทช., Post Today,
            ประชาชาติธุรกิจ, กรุุงเทพธุรกิจ, KSecurities, สำนักข่าวไทย,
            มติชนออนไลน์, Sarakadee Lite, SmartSME, pptv, amarin, MGR Online,
            starvingtime, Sanook News, เรื่องเล่าเช้านี้, wongnai, workpoint
            today, ข่าวสด, 7-11, Drama-Addict, Ch7HD News, Ch3ThailandNews,
            ThaiPBS, Kapook, ครัวคุณต๋อย, อีจัน, Chillpainai, ชีวิตติดรีวิว, CNN
            News, BBC News
          </div>
        </v-tooltip>
        <span>
          , Pantip
        </span>
      </li>
      <li>
        <span>
          การแสดงสินค้า E-Commerce : Amazon, Shopee
        </span>
      </li>
    </ul>
    <ul
      style="color: #828282"
      class="mx-5 my-5 "
      v-if="$route.name === 'Product'"
    >
      <li>
        <span>
          รายละเอียดทรัพยากรชีวภาพ : ThaiBiodiversity
        </span>
      </li>
      <li>
        <span>
          กราฟมูลค่าการนำเข้าและส่งออกของประเทศ : การค้าไทย
        </span>
      </li>
      <li>
        <span>
          กราฟแสดงจำนวนโพสต์ : Facebook
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip }"
              >mdi-information-outline Google @Google
            </v-icon>
          </template>
          <div>
            ไทยรัฐ, องค์การเภสัชกรรม, NSTDA - สวทช., Post Today,
            ประชาชาติธุรกิจ, กรุุงเทพธุรกิจ, KSecurities, สำนักข่าวไทย,
            มติชนออนไลน์, Sarakadee Lite, SmartSME, pptv, amarin, MGR Online,
            starvingtime, Sanook News, เรื่องเล่าเช้านี้, wongnai, workpoint
            today, ข่าวสด, 7-11, Drama-Addict, Ch7HD News, Ch3ThailandNews,
            ThaiPBS, Kapook, ครัวคุณต๋อย, อีจัน, Chillpainai, ชีวิตติดรีวิว, CNN
            News, BBC News
          </div>
        </v-tooltip>
        <span>
          , Pantip
        </span>
      </li>
      <li>
        <span>
          การแสดงสินค้า E-Commerce : Amazon, Shopee
        </span>
      </li>
      <li>
        <span>
          การแสดงสิ่งบ่งชี้ทางภูมิศาสตร์ : กรมทรัพย์สินทางปัญญา
        </span>
      </li>
      <li>
        <span>
          การแสดงจำนวนผลผลิตของทรัพยากรชีวภาพ : สำนักงานเศรษฐกิจการเกษตร
        </span>
      </li>
      <li>
        <span>
          การแสดงงานวิจัย : ThaiJo, ScienceDirect
        </span>
      </li>
    </ul>
    <div class="py-1" style="background-color: #000; color: #fff">
      <div class="container">
        © 2019 ALL RIGHTS RESERVED
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route.name === "Home");
  },
};
</script>

<style></style>
