<template>
  <v-container v-if="!detailSpecie">
    <v-data-table
      class="row-pointer"
      :headers="headers"
      :items="species"
      :items-per-page="15"
      @click:row="onClickRow"
    ></v-data-table>
    <v-row>
      <v-col> แหล่งที่มา: {{ referer }} </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <template v-if="detailSpecie">
      <v-simple-table v-if="typeof detailSpecie.attributes === 'object'">
        <template v-slot:default>
          <thead>
            <tr>
              <th>attribute</th>
              <th>value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in detailSpecie.attributes" :key="index">
              <td>{{ data.attribute }}</td>
              <td>{{ data.value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </v-container>
</template>

<script>
export default {
  name: "SpecieTable",
  data() {
    return {
      headers: [{ text: "Science Name", value: "sciName" }],
      species: [],
      referer: null,
      detailSpecie: null,
    };
  },
  async mounted() {
    // this.detailSpecie = detailSpecie.attributes;

    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });
    const keyId = this.$route.params.keyId;

    let response;
    try {
      response = await this.$store.dispatch("product/getProductSpecie", keyId);
      this.species = response.data.items;
      this.referer = response.referer;
    } catch (err) {
      console.log(err.message);
    }

    loader.hide();
  },
  methods: {
    async onClickRow(item) {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      let response = await this.$store.dispatch(
        "product/getSpecieDetail",
        item.url
      );

      if (response.errorCode) {
        alert(response.message);
        loader.hide();
        return;
      }
      const data = response.data;

      this.detailSpecie = data;

      loader.hide();
    },
  },
};
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
