<template>
  <div>
    <v-layout class="justify-center">
      <v-flex>
        <v-layout class="justify-space-between" wrap>
          <!-- Graph 1 -->
          <v-flex
            v-if="
              JSON.parse(this.dataGraph1.stat_result_json).datasets[0].data
                .length > 0
            "
            lg6
            md6
            sm12
            xs12
            pr-md-6
            mt-sm-6
            class="justify-center"
          >
            <div class="text-center">
              ตารางแสดงมูลค่าการส่งออกของ
            </div>
            <div class="text-center">
              {{ dataGraph1.stat_description }}
            </div>
            <div class="font-12 mt-3 mb-2">
              มูลค่าการส่งออก
            </div>
            <v-layout>
              <v-flex>
                <LineChart
                  style="height: 300px"
                  :chartdata="dataGraph1Convert"
                />
              </v-flex>
              <v-flex xs1 class="d-flex align-end pb-5 font-12">
                ปี พ.ศ.
              </v-flex>
            </v-layout>
            <v-layout mt-2 justify-space-between>
              <v-spacer></v-spacer>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <span
                  style="width: 50px;height: 3px;background-color: #1877E3"
                ></span>
                <span class="ml-3">Y</span>
              </v-flex>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <div class="line-dashed"></div>
                <span class="ml-3">Predicted</span>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
          </v-flex>
          <v-flex v-else class="ma-auto text-center">
            <div>ไม่มีข้อมูล</div>
          </v-flex>

          <v-divider
            :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
          ></v-divider>

          <!-- Graph 2 -->
          <v-flex
            v-if="
              JSON.parse(this.dataGraph2.stat_result_json).datasets[0].data
                .length > 0
            "
            lg6
            md6
            sm12
            xs12
            pl-md-6
            mt-sm-6
            class="justify-center"
          >
            <div class="text-center">
              ตารางแสดงมูลค่าการนำเข้าของ
            </div>
            <div class="text-center">
              {{ dataGraph2.stat_description }}
            </div>
            <div class="font-12 mt-3 mb-2">
              มูลค่าการนำเข้า
            </div>
            <v-layout>
              <v-flex>
                <LineChart
                  style="height: 300px"
                  :chartdata="dataGraph2Convert"
                />
              </v-flex>
              <v-flex xs1 class="d-flex align-end pb-5 font-12">
                ปี พ.ศ.
              </v-flex>
            </v-layout>
            <v-layout mt-2 justify-space-between>
              <v-spacer></v-spacer>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <span
                  style="width: 50px;height: 3px;background-color: #1877E3"
                ></span>
                <span class="ml-3">Y</span>
              </v-flex>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <div class="line-dashed"></div>
                <!-- <div class="line2"></div> -->
                <span class="ml-3">Predicted</span>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
          </v-flex>
          <v-flex v-else class="ma-auto text-center">
            <div>ไม่มีข้อมูล</div>
          </v-flex>
        </v-layout>

        <!-- <v-divider class="my-6"></v-divider> -->

        <!-- <v-layout class="justify-space-between" wrap> -->
        <!-- Graph 3 -->
        <!-- <v-flex lg6 md6 sm12 xs12 pr-6 class="justify-center">
            <div class="text-center">
              ตารางแสดงการพยากรณ์มูลค่าการส่งออกของ
            </div>
            <div class="text-center">
              {{ dataGraph3.stat_description }}
            </div>
            <div class="font-12 mt-3 mb-2">
              มูลค่าการส่งออก
            </div>
            <v-layout>
              <v-flex>
                <LineChart
                  style="height: 300px"
                  :chartdata="JSON.parse(dataGraph3.stat_result_json)"
                />
              </v-flex>
              <v-flex xs1 class="d-flex align-end pb-5 font-12">
                ปี พ.ศ.
              </v-flex>
            </v-layout>
            <v-layout mt-2 justify-space-between>
              <v-spacer></v-spacer>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <span
                  style="width: 50px;height: 3px;background-color: #1877E3"
                ></span>
                <span class="ml-3">Y</span>
              </v-flex>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <div class="line-dashed"></div>
                <div class="line2"></div>
                <span class="ml-3">Predicted</span>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
          </v-flex>

          <v-divider
            :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
          ></v-divider> -->

        <!-- Graph 4 -->
        <!-- <v-flex lg6 md6 sm12 xs12 pl-6 class="justify-center">
            <div class="text-center">
              ตารางแสดงการพยากรณ์มูลค่าการส่งออกของ
            </div>
            <div class="text-center">
              {{ dataGraph4.stat_description }}
            </div>
            <div class="font-12 mt-3 mb-2">
              มูลค่าการส่งออก
            </div>
            <v-layout>
              <v-flex>
                <LineChart
                  style="height: 300px"
                  :chartdata="JSON.parse(dataGraph4.stat_result_json)"
                />
              </v-flex>
              <v-flex xs1 class="d-flex align-end pb-5 font-12">
                ปี พ.ศ.
              </v-flex>
            </v-layout>
            <v-layout mt-2 justify-space-between>
              <v-spacer></v-spacer>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <span
                  style="width: 50px;height: 3px;background-color: #1877E3"
                ></span>
                <span class="ml-3">Y</span>
              </v-flex>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <div class="line-dashed"></div>
                <div class="line2"></div>
                <span class="ml-3">Predicted</span>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
          </v-flex> -->
        <!-- </v-layout> -->
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import LineChart from "../Chart/marketValue/LineChart.vue";
export default {
  components: { LineChart },
  props: {
    dataGraph1: null,
    dataGraph2: null,
    dataGraph3: null,
    dataGraph4: null,
  },
  computed: {
    dataGraph1Convert() {
      let dataParse = JSON.parse(this.dataGraph1.stat_result_json);

      if (dataParse) {
        dataParse = this.datasetsPointRadiusAdd(dataParse);

        return dataParse;
      } else {
        return null;
      }
    },
    dataGraph2Convert() {
      let dataParse = JSON.parse(this.dataGraph2.stat_result_json);

      if (dataParse) {
        dataParse = this.datasetsPointRadiusAdd(dataParse);

        return dataParse;
      } else {
        return null;
      }
    },
  },
  mounted() {
    // console.log("market value", JSON.parse(this.dataGraph1.stat_result_json));
    // console.log("market value", JSON.parse(this.dataGraph2.stat_result_json));
  },
  methods: {
    datasetsPointRadiusAdd(data) {
      console.log("graphj market value", data);
      data.datasets = data.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 5,
      }));

      return data;
    },
  },
};
</script>

<style>
.line-dashed {
  width: 50%;
  margin: 5px 0;
  height: 2px;
  background: repeating-linear-gradient(
    to right,
    #c4fb79 0,
    #c4fb79 10px,
    transparent 10px,
    transparent 12px
  );
}
</style>
