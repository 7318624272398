<template>
  <div>
    <v-layout>
      <v-flex class="mr-2 shrink" style="width: 100%;">
        <div :style="subTopicStyle" :class="subClass">
          {{ subTopic }}
        </div>
        <div>
          <v-row>
            <v-col>
              <div :style="mainTopicStyle" :class="mainClass">
                {{ mainTopic }}
              </div>
            </v-col>
            <v-col v-if="download" class="text-end">
              <v-btn rounded @click="download()" dark color="#1877E3"
                >Download</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-flex>
    </v-layout>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    mainTopic: {
      type: String,
      default: "",
    },
    subTopic: {
      type: String,
      default: "",
    },
    mainTopicStyle: {
      type: String,
      default: "font-size: 24px",
    },
    subTopicStyle: {
      type: String,
      default: "font-size: 32px",
    },
    subClass: {
      type: String,
      default: "",
    },
    mainClass: {
      type: String,
      default: "font-weight-bold",
    },
    download: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style></style>
