import Service from "../Service";

const category = {
  getCategoryStat(payload) {
    return Service.get(`/api/category/${payload.id}`);
  },
  getCategory(payload) {
    return Service.get(`/api/category`);
  },
  getTop10(id) {
    return Service.get(`/api/top-10?id=${id}`);
  },
  getBioTypes() {
    return Service.get(`/api/bio-types`);
  },
};

export default category;
