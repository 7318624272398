<template>
  <div>
    <!-- Navbar -->
    <Navbar />

    <!-- Heroes -->
    <Heroes v-if="$route.name === 'Home'" />

    <div
      v-if="$route.name === 'Home'"
      style="width: 100%; background-color: #052E5C;"
    >
      <v-container class="pa-15">
        <v-row class="text-center">
          <v-col class="pa-0 ">
            <span style="color: white;">
              ความหลากหลายทางชีวภาพ หมายถึง การมีสิ่งมีชีวิตนานาชนิด
              นานาพันธุ์ในระบบนิเวศอันเป็นแหล่งที่อยู่อาศัย
            </span>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col class="pa-0 ">
            <span style="color: white;">
              ซึ่งมีมากมายและแตกต่างกันทั่วโลก หรือ การที่มีชนิดพันธุ์ (species)
              สายพันธุ์ (genetic)
            </span>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col class="pa-0">
            <span style="color: white;">
              และระบบนิเวศ (ecosystem) ที่แตกต่างหลากหลายบนโลก
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Content -->
    <v-container
      :style="
        $vuetify.breakpoint.mdAndUp ? `min-height: calc(100% - 300px)` : ``
      "
    >
      <router-view />
    </v-container>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Heroes from "./Heroes.vue";
import Navbar from "./Navbar.vue";
export default {
  components: { Navbar, Footer, Heroes },
};
</script>

<style></style>
