import Service from "../../services";

const state = {
  productCategoryList: null,
};

const getters = {};

const actions = {
  getProduct({ commit }, id) {
    return Service.product.getProduct(id).then((res) => {
      return res;
    });
  },
  getProductCategory({ commit }) {
    return Service.product.getProductCategory();
    // .then((res) => {
    //   console.log(res);
    //   commit("SET_PRODUCT_CATEGORY", res);
    //   // return res;
    // });
  },
  getAllProducts({ commit }) {
    return Service.product.getAllProducts();
  },
  getInResearchList({ commit }, payload) {
    return Service.product.getInResearchList(payload);
  },
  getProductSpecie({ commit }, id) {
    return Service.product.getProductSpecie(id);
  },
  getSpecieDetail({ commit }, url) {
    return Service.product.getSpecieDetail(url);
  },
};

const mutations = {
  SET_PRODUCT_CATEGORY(state, data) {
    state.productCategoryList = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
