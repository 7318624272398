import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import product from "./modules/Products.Store";
import category from "./modules/Category.Store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    product,
    category
  },
  plugins: [createPersistedState()],
});
