import Service from "../../services";

const state = {
  categoryStat: null,
  categoryOnlineTrendingByProduct: null,
  categoryOnlineTrendingByEmotion: null,
  categoryTopTenExport: null,
  categoryEconomicGrowthRate: null,
  categoryEcommerceSocialIn: null,
  categoryEcommerceSocialOut: null,
  category: null,
  isActiveCategory: 1,

  // isHaveData เอาไว้ check response ที่ได้จาก function getCategoryStat
  // response มีค่า ==> SET_CATEGORY_STAT
  // response ไม่มีค่า([]) ==> CLEAR_CATEGORY_STAT
  isHaveData: 1,
};

const getters = {};

const actions = {
  getCategoryStat({ commit }, payload) {
    return Service.category.getCategoryStat(payload).then((res) => {
      res.length > 0
        ? commit("SET_CATEGORY_STAT", res)
        : commit("CLEAR_CATEGORY_STAT");

      // SET active Category when click
      commit("SET_ACTIVE_CATEGORY", payload.id);
      return res;
    });
  },
  getCategory({ commit }) {
    return Service.category.getCategory();
    // .then((res) => {
    //   commit("SET_CATEGORY", res);
    //   return res;
    // });
  },
  getBioTypes({ commit }) {
    return Service.category.getBioTypes();
    // .then((res) => {
    //   commit("SET_CATEGORY", res);
    //   return res;
    // });
  },
  getTop10({ commit }, id) {
    return Service.category.getTop10(id).then((res) => {
      // SET active Category when click
      commit("SET_ACTIVE_CATEGORY", id);
      return res;
    });
  },
};

const mutations = {
  SET_ACTIVE_CATEGORY(state, data) {
    state.isActiveCategory = data;
  },
  SET_LOADING(state, data) {
    state.isHaveData = data;
  },
  CLEAR_CATEGORY_STAT(state) {
    state.categoryOnlineTrendingByProduct = null;
    state.categoryOnlineTrendingByEmotion = null;
    state.categoryTopTenExport = null;
    state.categoryEconomicGrowthRate = null;
    state.categoryEcommerceSocialIn = null;
    state.categoryEcommerceSocialOut = null;
    state.isHaveData = 2;
  },
  SET_CATEGORY_STAT(state, data) {
    state.isHaveData = 1;
    state.categoryOnlineTrendingByProduct = data.find(
      (x) => x.category_stat_name_id === 2
    );
    state.categoryOnlineTrendingByProduct.category_stat_result_json = JSON.parse(
      state.categoryOnlineTrendingByProduct.category_stat_result_json
    );
    state.categoryOnlineTrendingByEmotion = data.find(
      (x) => x.category_stat_name_id === 3
    );
    state.categoryOnlineTrendingByEmotion.category_stat_result_json = JSON.parse(
      state.categoryOnlineTrendingByEmotion.category_stat_result_json
    );

    state.categoryTopTenExport = data.find(
      (x) => x.category_stat_name_id === 4
    );
    state.categoryEconomicGrowthRate = data.find(
      (x) => x.category_stat_name_id === 1
    );
    state.categoryEcommerceSocialIn = data.find(
      (x) => x.category_stat_name_id === 5
    );
    state.categoryEcommerceSocialOut = data.find(
      (x) => x.category_stat_name_id === 6
    );
  },
  SET_CATEGORY(state, data) {
    state.category = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
