<template>
  <div>
    <v-layout class="justify-center">
      <v-flex>
        <v-layout class="justify-space-between" wrap>
          <!-- Graph 1 -->
          <v-flex
            v-if="
              JSON.parse(dataGraph1.stat_result_json).datasets[0].data.length >
                0
            "
            lg6
            md6
            sm12
            xs12
            pr-md-6
            class="justify-center"
          >
            <div class="font-12 mt-3 mb-2">
              จำนวนโพสต์
            </div>
            <v-layout>
              <v-flex>
                <OneLineChart
                  :data="JSON.parse(dataGraph1.stat_result_json)"
                  style="height: 300px"
                />
              </v-flex>
              <v-flex xs1 class="d-flex align-end pb-5 font-12">
                เดือน
              </v-flex>
            </v-layout>
            <v-layout mt-2 justify-space-between>
              <v-spacer></v-spacer>
              <v-flex xs3 class="font-12 d-flex align-center flex-row">
                <span
                  style="width: 15px;height: 15px;background-color: #1877E3"
                ></span>
                <span class="ml-3">
                  {{
                    JSON.parse(dataGraph1.stat_result_json).datasets[0].label
                  }}
                </span>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
          </v-flex>
          <v-flex v-else class="ma-auto text-center">
            <div>ไม่มีข้อมูล</div>
          </v-flex>

          <v-divider
            :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
          ></v-divider>

          <!-- Graph 2 -->
          <v-flex
            v-if="this.series.length > 0"
            lg6
            md6
            sm12
            xs12
            pl-md-6
            class="justify-center"
          >
            <!-- https://apexcharts.com/docs/responsive/ -->
            <div>
              <apexchart
                height="400"
                type="pie"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </v-flex>
          <v-flex v-else class="ma-auto text-center">
            <div>ไม่มีข้อมูล</div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import OneLineChart from "../Chart/OnlineTrending/OneLineChart.vue";
export default {
  components: {
    OneLineChart,
    // PieChart
  },
  props: {
    dataGraph1: null,
    dataGraph2: null,
  },
  data() {
    return {
      options: {
        labels: [],
        colors: ["#F44336", "#E91E63", "#9C27B0"],
        fill: {
          colors: [],
        },
        legend: {
          position: "bottom",
          markers: {
            width: 15,
            height: 15,
            radius: 10,
          },
        },
        chart: {
          fontFamily: "Prompt, Arial, sans-serif",
        },
        tooltip: {
          fillSeriesColor: true,
        },
      },
      series: [],
    };
  },
  created() {
    this.options.labels = this.dataGraph2.labels;
    this.series = this.dataGraph2.datasets[0].data;
    this.options.fill.colors = this.dataGraph2.datasets[0].backgroundColor;
    this.options.colors = this.dataGraph2.datasets[0].backgroundColor;
  },
};
</script>
<style></style>
