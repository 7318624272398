<template>
  <v-row
    class="justify-space-between px--6 py-2 ma-auto"
    style="background-color: #001034; overflow: auto"
  >
    <v-col
      cols="12"
      md="3"
      class="shrink d-flex align-center cursor--pointer"
      @click="$router.push('/')"
    >
      <img
        class="mx-auto mx-md-0"
        width="100"
        src="../../assets/images/logo.png"
        alt=""
      />
    </v-col>
    <v-col
      cols="12"
      md="9"
      class="xs4 d-flex align-center justify-end"
      style="color: #fff"
    >
      <v-row justify="end">
        <v-col align-self="end" cols="12" md="auto" class="my-auto text-center">
          <v-btn
            text
            dark
            :color="$route.name === 'Home' ? '#02B25F' : '#fff'"
            class="font-weight-bold"
            @click="$router.push('/')"
          >
            สถิติหน้าหลัก
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto" class="my-auto text-center">
          <v-btn
            text
            dark
            :color="$route.name === 'Top10' ? '#02B25F' : '#fff'"
            class="font-weight-bold"
            @click="$router.push('/top10/1')"
          >
            สถิติ TOP10
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto" class="my-auto text-center">
          <div>
            <v-autocomplete
              class="px-md-2 px-0"
              background-color="white"
              placeholder="ค้นหา ..."
              rounded
              style="width: 100%; max-width: 700px;"
              :items="products"
              :item-text="textSearch"
              item-value="id"
              @change="searchNavHandler"
            >
              <template slot="append">
                <v-btn
                  height="30"
                  style="min-width: 0;margin-top: 0;background-color: #15B86B; border-radius: 50px; color:white; "
                  class="mb-1 pa-1"
                >
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" md="auto" class="my-auto text-center">
          <v-btn
            @click="searchHandler()"
            style="margin-top: 0;background-color: #1877E3; border-radius: 50px; color:white; "
            class="px-5 mb-1"
            >ค้นหาขั้นสูง</v-btn
          >
        </v-col>
        <v-col cols="12" md="auto" class="my-auto text-center">
          <v-btn dark rounded :color="'#02B25F'" @click="adminRedirect()">
            สำหรับหน่วยงาน
          </v-btn>
        </v-col>
      </v-row>

      <!-- <v-btn
        text
        dark
        :color="$route.name === 'Search' ? '#C4FB79' : '#fff'"
        class="font-weight-bold"
        @click="$router.push('/search')"
      > -->
      <!-- ค้นหาทรัพยากร -->
      <!-- <v-icon>
          mdi-magnify
        </v-icon> -->
      <!-- </v-btn> -->
    </v-col>

    <!-- <v-flex class="xs4 d-flex align-center search--nav">
      <v-layout class="input--search">
        <v-flex class=" d-flex align-center">
          <v-text-field
            flat
            solo
            dense
            color="#fff"
            hide-details
            label="ค้นหา..."
            v-model="keyword"
            @keyup.enter="onHandleSearch()"
          ></v-text-field>
        </v-flex>
        <v-flex class="xs1 d-flex align-center">
          <v-btn
            depressed
            fab
            small
            width="30"
            height="30"
            color="#02b25f"
            @click="onHandleSearch()"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex> -->
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { adminDomain } from "../../services/Constants";
export default {
  data() {
    return {
      keyword: "",
      products: [],
      adminDomain,
    };
  },
  async created() {
    let products = await this.getAllProducts();
    this.products = products;
    // this.submit();
  },
  methods: {
    ...mapActions("product", ["getAllProducts"]),
    adminRedirect() {
      window.open(`${adminDomain}`);
    },
    searchNavHandler(value) {
      this.$router.push(`/product/${value}`);
      window.location.reload();
    },
    searchHandler() {
      this.$router.push("/search");
    },
    textSearch(item) {
      return `${item.name_th} (${item.name_en})`;
    },
  },
};
</script>

<style>
/* Custom Text Input */
.input--search {
  background-color: #fff;
  border-radius: 20px;
}
.search--nav .v-input__icon.v-input__icon--append {
  background-color: #02b25f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.search--nav i.v-icon.notranslate.mdi.mdi-magnify.theme--light {
  color: #fff;
}
.search--nav
  .v-text-field.v-text-field--solo.v-input--dense
  > .v-input__control {
  border-radius: 21px;
  min-height: 35px;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
  padding-top: 0px;
  padding-right: 6px !important;
  padding-bottom: 0px;
  padding-left: 20px !important;
}

.v-input__slot {
  padding: 0 12px 0 24px !important;
}

.v-select__slot > input {
  margin: auto !important;
}
</style>
