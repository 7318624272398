<template>
  <div>
    <v-row class="justify-center">
      <v-col>
        <v-row class="justify-center" wrap>
          <!-- Graph 1 -->
          <v-col cols="12" md="4" sm="12" pr-6 class="justify-center">
            <v-row justify="start">
              <v-col class="pa-md-5" cols="12" lg="10">
                <div
                  style="width: auto;background-color: #BAD6F7; padding: 2rem ; margin 0 5rem; border-radius: 25px;"
                >
                  <div class="font-20 text-center font-weight-bold">
                    จำนวนสินค้า ECOMMERCE ในประเทศ
                  </div>
                  <div class="font-20 text-center font-weight-bold">
                    {{ series[0].data[0] }} รายการ
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="pa-md-5" cols="12" lg="10">
                <div
                  style="background-color: #CCF0FB; padding: 2em; border-radius: 25px;"
                >
                  <div class="font-20 text-center font-weight-bold">
                    จำนวนยอดขาย ECOMMERCE ในประเทศ
                  </div>
                  <div class="font-20 text-center font-weight-bold">
                    {{ series[0].data[1] }} รายการ
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col class="pa-md-5" cols="12" lg="10">
                <div
                  style="background-color: #B3E8CF; padding: 2em; border-radius: 25px;"
                >
                  <div class="font-20 text-center font-weight-bold">
                    จำนวนสินค้า ECOMMERCE ต่างประเทศ
                  </div>
                  <div class="font-20 text-center font-weight-bold">
                    {{ series[0].data[2] }} รายการ
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- <div class="font-12 pl-10 font-weight-bold">
              จำนวน Rating
            </div>

            <v-layout>
              <v-flex xs11>
                <apexchart
                  height="400"
                  type="bar"
                  :options="options"
                  :series="series"
                ></apexchart>
              </v-flex>
              <v-flex
                class="d-flex align-center font-12 ml-n4 font-weight-bold"
                style="padding-top: 9rem"
              >
                หมวดหมู่
              </v-flex>
            </v-layout> -->
          </v-col>

          <v-divider
            :vertical="$vuetify.breakpoint.mdAndUp ? true : false"
          ></v-divider>

          <!-- Graph 2 -->
          <v-col cols="12" md="8" pl-6>
            <v-row justify="center">
              <!-- Shopee -->
              <v-col class="mx-md-1" cols="12" sm="5">
                <div class="font-20 text-center">
                  สินค้ายอดนิยมในประเทศ
                </div>
                <v-row
                  v-if="Object.values(shopee)"
                  justify-start
                  py-2
                  class="product-shopee align-center slim-scroll"
                  style="height: 450px; overflow: auto;"
                >
                  <v-col
                    v-for="(item, i) in shopee"
                    :key="i"
                    class="d-flex justify-center align-center"
                    cols="12"
                    md="auto"
                  >
                    <CardProduct
                      :tilte="item.product_name_th"
                      :detail="item.detail"
                      :price="item.price"
                      :image="item.image"
                      :url="item.url"
                      :brand="item.brand"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  justify-start
                  py-2
                  class="product-shopee flex-column"
                  style="height: 350px; overflow: auto;"
                >
                  <v-col class="d-flex  align-center">ไม่มีสินค้า</v-col>
                </v-row>
              </v-col>

              <!-- Amazon -->
              <v-col class="mx-md-1" cols="12" sm="5">
                <div class="font-20 text-center">
                  สินค้ายอดนิยมต่างประเทศ
                </div>
                <v-row
                  v-if="Object.values(amazon).length > 0"
                  justify-start
                  py-2
                  class="product-shopee  slim-scroll"
                  style="height: 450px; overflow: auto;"
                >
                  <v-col
                    v-for="(item, i) in amazon"
                    :key="i"
                    class="d-flex justify-center align-center"
                    cols="12"
                    md="auto"
                  >
                    <CardProduct
                      :tilte="item.product_name_th"
                      :detail="item.detail"
                      :price="item.price"
                      :image="item.image"
                      :url="item.url"
                      :brand="item.brand"
                      currency="dollars"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  justify-center
                  py-2
                  class="product-shopee flex-column"
                  style="height: 350px; overflow: auto;"
                >
                  <v-col class="d-flex justify-center align-center"
                    >ไม่มีสินค้า</v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardProduct from "../CardProduct.vue";
export default {
  components: { CardProduct },
  props: {
    dataGraph1: null,
    dataGraph2: null,
  },
  data() {
    return {
      series: [],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            dataLabels: {
              position: "top",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            inverseColors: false,
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 1,
            gradientToColors: ["#6B6FD9", "#C4FB79"],
            opacityTo: 1,
            stops: [0, 100, 10],
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        chart: {
          toolbar: {
            show: false,
          },
        },

        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "gray",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            formatter: function(value) {
              return value.split(" ");
            },
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "gray",
              offsetX: 0,
              offsetY: 0,
            },
          },
          {
            opposite: true,
            axisBorder: {
              show: true,
              color: "gray",
            },
            labels: {
              show: false,
            },
          },
        ],

        tooltip: {
          shared: true,
          intersect: false,
        },
      },
      shopee: [],
      amazon: [],
    };
  },
  created() {
    //*     map data graph 1
    this.series = JSON.parse(this.dataGraph1.stat_result_json).datasets;
    this.series.forEach((data) => {
      data["name"] = "";
    });
    this.options.xaxis.categories = JSON.parse(
      this.dataGraph1.stat_result_json
    ).labels;

    //*     map data graph 2
    this.shopee = JSON.parse(this.dataGraph2.stat_result_json).shopee;

    this.amazon = JSON.parse(this.dataGraph2.stat_result_json).amazon;

    console.log(Object.values(this.amazon));
  },
  methods: {},
};
</script>

<style>
.shopee {
  /* position: relative; */
  width: 100%;
  background: #ffffff;
  border: 2px solid #ee4d30;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.product-shopee {
  margin-top: 1rem;
  background: #faf8f8;
  border-radius: 5px;
}

.slim-scroll::-webkit-scrollbar {
  width: 4px;
}
.slim-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #278577;
  outline: 4px solid #278577;
}
</style>
