<template>
  <div v-if="categoryEconomicGrowthRate">
    <v-layout class="text-end pb-2">
      <v-flex>
        <v-btn rounded color="#02B25F" dark @click="dataFilterHandler()">
          <img :src="filterIcon" />
          <span class="pl-2">เลือกแสดง</span>
        </v-btn>
        <v-select
          ref="selectFilter"
          class="economic v-select--is-menu-active"
          v-model="value"
          :items="dataFilter.datasets"
          item-text="label"
          item-value="label"
          attach
          chips
          autofocus
          dense
          return-object
          color="#02B25F"
          outlined
          :menu-props="{
            offsetY: true,
            value: filterToggle,
            openOnClick: true,
          }"
          multiple
          @change="onChange()"
          v-if="filterToggle"
        ></v-select>
      </v-flex>
    </v-layout>

    <v-layout class="mb-2 font-14 justify-space-around">
      <v-flex>
        มูลค่าการส่งออก(หมื่นล้านบาท)
      </v-flex>
      <v-flex class="text-end">
        GDP (พันล้านบาท)
      </v-flex>
    </v-layout>

    <LineChart
      v-if="data"
      style="height: 300px"
      :chartdata="data"
      :options="this.options"
    />

    <!-- chart legend -->
    <v-row pl-6 mt-4>
      <v-col v-for="(item, i) in dataFilter.datasets" :key="i">
        <v-layout>
          <v-flex class="shrink d-flex pt-1 mr-1">
            <div
              style="height: 7px;width: 30px"
              :style="{ backgroundColor: item.borderColor }"
            ></div>
          </v-flex>

          <v-flex style="" class="font-12 d-flex flex-start">
            {{ item.label }}
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LineChart from "../Chart/economicGrowthRate/LineChart.vue";
import { mapState } from "vuex";

export default {
  components: { LineChart },
  data: () => ({
    dataFilter: {
      datasets: [],
    },
    filterIcon: require("../../assets/images/icon/filter.png"),
    filterToggle: false,
    value: [],
    data: null,
    valueStepSize: 0,
    gdpStepSize: 0,
  }),
  watch: {
    data: function(val) {
      // if (val.datasets?.length == 0)
      //   this.data = JSON.parse(
      //     this.categoryEconomicGrowthRate.category_stat_result_json
      //   );
      // console.log(val.datasets, this.data);
    },
    categoryEconomicGrowthRate: function(val) {
      // if (val.datasets?.length == 0)
      this.data = JSON.parse(val.category_stat_result_json);

      this.dataFilter = JSON.parse(val.category_stat_result_json);

      this.dataFilter = this.datasetsPointRadiusAdd(this.dataFilter);

      this.data = this.datasetsPointRadiusAdd(this.data);

      this.stepSizeCalculator(this.data);

      // console.log(val.datasets, this.data);
    },
  },
  mounted() {
    if (this.categoryEconomicGrowthRate) {
      this.dataFilter = JSON.parse(
        this.categoryEconomicGrowthRate.category_stat_result_json
      );
      this.data = JSON.parse(
        this.categoryEconomicGrowthRate.category_stat_result_json
      );

      this.dataFilter = this.datasetsPointRadiusAdd(this.dataFilter);

      this.data = this.datasetsPointRadiusAdd(this.data);

      this.stepSizeCalculator(this.data);
    }
  },
  computed: {
    ...mapState({
      categoryEconomicGrowthRate: (state) =>
        state.category.categoryEconomicGrowthRate,
    }),
    options() {
      return {
        plugins: {
          legend: {
            labels: {
              defaultFontFamily: "Prompt",
              defaultFontColor: "#9a8",
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontFamily: "Prompt",
            fontStyle: "bold",
          },
          fullWidth: false,
        },

        elements: {
          line: {
            tension: 0,
          },
        },

        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                reverse: false,
                beginAtZero: true,
                stepSize: this.valueStepSize,
              },
            },
            {
              id: "GDP",
              position: "right",
              ticks: {
                min: 0,
                // stepSize: 1000,
                stepSize: this.gdpStepSize,
                reverse: false,
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    datasetsPointRadiusAdd(data) {
      console.log(data, "ee");
      data.datasets = data.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 5,
      }));

      return data;
    },
    stepSizeCalculator(data) {
      if (data) {
        let maxValue = 0;
        let maxGDP = 0;
        data.datasets.forEach((dataset) => {
          if (dataset.yAxisID == "GDP") {
            maxGDP = Math.max(...dataset.data);
          } else {
            maxValue = Math.max(...[...dataset.data, maxValue]);
          }
        });

        this.valueStepSize = maxValue / 10;
        this.gdpStepSize = maxGDP / 10;
      } else {
        return data;
      }
    },
    dataFilterHandler() {
      this.filterToggle = !this.filterToggle;

      this.$nextTick(() => {
        if (this.$refs["selectFilter"]) {
          this.$refs["selectFilter"].activateMenu();
        }
      });
    },
    onChange() {
      const datasets = this.dataFilter.datasets;
      const labels = this.dataFilter.labels;
      let newData = [];

      if (this.value.length > 0) {
        for (const item of this.value) {
          newData.push(datasets.find((x) => item.label === x.label));
        }
      } else {
        newData = datasets;
      }

      this.data = {
        datasets: newData,
        labels: labels,
      };
    },
  },
};
</script>

<style></style>
